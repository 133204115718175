import React from 'react'


import {
    procedure_creation_config,
    bridge_creation_fields,
    building_creation_fields,
    windmill_creation_fields, facility_creation_fields
} from "../../FormConfigs/procedure_config";
import { useForm, useWatch } from "react-hook-form";
import { AssetTypeEnum } from "../../constants";
import { useQueryAllProcedures } from "../queryHooks/procedureQueries";
import { createSelect } from "../../FormConfigs/utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";


export default function usePostProcedureForm() {
    const {t} = useTranslation()
    const companyId = useLocation().state.companyId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: { type: AssetTypeEnum.BRIDGE },
    })
    
    const watchType = useWatch({
        control: methods.control,
        name: 'type',
    })
    
    const {data: procedures} = useQueryAllProcedures({enabled: watchType === AssetTypeEnum.FACILITY}, {companyId})
    
    
    
    const fields = React.useMemo(() => {
        
        return {
            ...procedure_creation_config,
            ...(watchType === AssetTypeEnum.BRIDGE && bridge_creation_fields),
            ...(watchType === AssetTypeEnum.BUILDING && building_creation_fields),
            ...(watchType === AssetTypeEnum.WINDMILL && windmill_creation_fields),
            ...(watchType === AssetTypeEnum.FACILITY && {
                ...facility_creation_fields,
                type_specific_fields: {
                    _type: 'group',
                    __meta: {hideGroupUI: true},
                    procedure_ids: createSelect((procedures || []).map(procedure => ({value: procedure.id, label: procedure.name})), true, t('procedures')),
                }
            })
        }
    }, [watchType, procedures, t])
    
    return {methods, fields, formId: `create_procedure_${watchType}`}
}