import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimeRangePicker } from '../../components/DateRangePicker'
import { SeismicMonitoringGraphs } from '../../components/SeismicMonitoring/SeismicMonitoringGraphs'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'
import { createUseQueryArg } from "../../customHooks/createUseParam";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
}))

const useArgStart = createUseQueryArg({
    paramName: "argStart",
    setterName: "setArgStart",
    paramKey: "interval_begin",
})
const useArgEnd = createUseQueryArg({
    paramName: "argEnd",
    setterName: "setArgEnd",
    paramKey: "interval_end"
})

export const SeismicMonitoringTelemetry = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    
    const { argStart, setArgStart } = useArgStart()
    const { argEnd, setArgEnd } = useArgEnd()
    
    const start = React.useMemo(() => !!argStart ? dayjs(argStart) : dayjs().subtract(1, 'hour'), [argStart])
    const end = React.useMemo(() => argEnd ? dayjs(argEnd) : dayjs(), [argEnd])
    
    const setStart = (value) => setArgStart(value.toISOString())
    const setEnd = (value) => setArgEnd(value.toISOString())
    

    return (
        <MethodologyPage methodology="seismic_monitoring" maxWidth="lg">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('seismic_monitoring'))}
                </Typography>
                <Box marginY={2}>
                    <DateTimeRangePicker dateTimeRange={{ start, end, setStart, setEnd }} />
                </Box>
                <SeismicMonitoringGraphs startIsoString={start.toISOString()} endIsoString={end.toISOString()} />
            </Paper>
        </MethodologyPage>
    )
}
