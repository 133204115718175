import {
    alertChar, alertLevelScore,
    eventSequentialId,
    MethodologyFab,
    MethodologySectionPaper,
    MethodologySectionTitle
} from "../../components";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../../utils";
import { useQuerySismaToolEvents } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useDialogControls from "../../../../customHooks/useDialogControls";
import CreateEventDialog from "../dialogs/CreateEventDialog";
import { OpenInNew } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import useProcedureId from "../../../../customHooks/useProcedureId";
import useSismaToolRoles from "../hooks/useSismaToolRoles";



function Events() {
    const { t } = useTranslation()
    const { data } = useQuerySismaToolEvents()
    const events = React.useMemo(() => {
        const events = data || []
        events.forEach(event => {})
        return events
    }, [data])
    const { push } = useHistory()
    const { procedureId } = useProcedureId()
    const {isHse, isRspp} = useSismaToolRoles()
    
    
    const createEventDialogControls = useDialogControls()
    const eventTableColumns = [
        {
            header: 'id evento',
            renderCell: event => eventSequentialId(event),
            headerProps: {}
        },
        {
            header: 'allerta',
            renderCell: event => `${alertChar(alertLevelScore({alert_level: event?.aggregate_alert_level}))} ${(event.pga_max_microg / 10_000).toFixed(2)}`
        },
        {
            header: 'dettaglio',
            renderCell: event => (
                <IconButton>
                    <OpenInNew
                        fontSize={'small'}
                        onClick={() => push(`/procedures/${procedureId}/hexo/events/${event?.id}`)}
                    />
                </IconButton>
            )
        }
    ]
    
    return (
        <MethodologySectionPaper>
            <MethodologySectionTitle>
                {capitalizeFirstLetter(t('hexo.latest_events'))}
            </MethodologySectionTitle>
            
            {!!events?.length && (<TableContainer><Table>
                <TableHead>
                    {eventTableColumns.map(column => (
                        <TableCell key={column.header} {...(column?.headerProps ?? {})}>
                            {t(column.header)}
                        </TableCell>
                    ))}

                </TableHead>
                <TableBody>
                    {events.map(event => (
                        <TableRow key={event.id}>
                            {/*<pre>{JSON.stringify(event, null, 2)}</pre>*/}
                            {eventTableColumns.map(column => <TableCell key={column.header}>{column?.renderCell(event)}</TableCell>)}
                    
                        </TableRow>
                    ))}
                </TableBody>
            </Table></TableContainer>)}
            <MethodologyFab onClick={(e) => createEventDialogControls.open()} disabled={!isHse && !isRspp}>
                <AddIcon />
                {t('create_event')}
            </MethodologyFab>
            
            <CreateEventDialog controls={createEventDialogControls}/>
            
        </MethodologySectionPaper>
    )
}

export default Events