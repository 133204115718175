import { methodologies } from "../constants";
import { useLocation, useParams } from "react-router-dom";

const methodologyPattern = new RegExp(`(?<=^/procedures/.{24}/)(${methodologies.join('|')})(?=/|$)`)
function useMethodology() {
    const {methodology: paramsMethodology} = useParams()
    const { pathname } = useLocation()
    
    if (methodologies.includes(paramsMethodology)) {
        return {methodology: paramsMethodology}
    }
    
    const [ methodology ] = pathname.match(methodologyPattern) || []
    return { methodology }
}

export default useMethodology