import { Grid } from '@material-ui/core'
import dayjs from 'dayjs'
import React from 'react'
import {
    Brush,
    Dot,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

// Converte l'hash in colore RGB usando una mappatura non lineare
function intToRGB(i) {
    // Dividiamo l'hash in componenti separate per massimizzare la diversità
    const hash = hashCode(i.toString())

    // Creiamo tre valori hash separati da componenti diverse dell'hash originale
    const h1 = (hash & 0xff) ^ ((hash >> 8) & 0xff)
    const h2 = ((hash >> 8) & 0xff) ^ ((hash >> 16) & 0xff)
    const h3 = ((hash >> 16) & 0xff) ^ ((hash >> 24) & 0xff)

    // Mappiamo questi valori in tre range di colore distinti
    // per assicurarci che i colori siano molto diversi
    const hue = (h1 * 137.508) % 360 // Distribuzione dell'angolo del colore

    // Usiamo curve non lineari per saturazione e luminosità
    const saturation = 0.85 + Math.sin(h2 * 0.1) * 0.15
    const lightness = 0.5 + Math.sin(h3 * 0.1) * 0.2

    // Convertiamo da HSL a RGB
    function hslToRgb(h, s, l) {
        const hueToRgb = (p, q, t) => {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        const r = hueToRgb(p, q, h / 360 + 1 / 3)
        const g = hueToRgb(p, q, h / 360)
        const b = hueToRgb(p, q, h / 360 - 1 / 3)

        return [r, g, b].map((x) => Math.round(x * 255))
    }

    // Convertiamo in esadecimale
    const [r, g, b] = hslToRgb(hue, saturation, lightness)
    const toHex = (x) => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}
// Funzione di hashing migliorata che combina più tecniche
function hashCode(str) {
    // Usiamo un mix di diversi algoritmi di hashing per massimizzare le differenze
    let hash1 = 5382
    let hash2 = 52711

    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash1 = ((hash1 << 5) + hash1) ^ char // djb2 modificato
        hash2 = ((hash2 << 5) + hash2) * char // variante moltiplicativa
    }

    // Combiniamo i due hash in modo non lineare
    return Math.abs((hash1 * 1578) ^ (hash2 * 2139)) >>> 0
}
const threshold_colors = ['#ff8c39', '#ff0000']
const dotRender =
    (group) =>
    ({ key, ...props }) => {
        if (props.payload.data[group.index] < group.thresholds_milli_g[0] * 1000) return false
        let fill
        if (props.payload.data[group.index] > group.thresholds_milli_g[1] * 1000) {
            fill = threshold_colors[1]
        } else fill = threshold_colors[0]
        return <Dot {...props} key={key} fill={fill} strokeWidth={0} />
    }

export const SeismicMonitoringGraph = ({ data, groups, onGroupToggle, showBrush = false }) => {
    console.log({data, groups})
    return (
        <Grid item xs>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} syncId="syncId">
                    <XAxis
                        dataKey="created_at_timestamp"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(unixTime) => dayjs.unix(unixTime).format('HH:mm:ss')}
                        scale="time"
                    />
                    <YAxis
                        unit="%g"
                        tickFormatter={(microg) => (microg / 10_000).toPrecision(4)}
                        width={70}
                    />
                    {groups.map((group, i) => {
                        return (
                            <React.Fragment key={group.name}>
                                {group.checked &&
                                    group.thresholds_milli_g.map((threshold_milli_g, i) => (
                                        <ReferenceLine
                                            key={group.name + threshold_milli_g}
                                            y={threshold_milli_g * 1000}
                                            stroke={threshold_colors[i] || 'black'}
                                            strokeDasharray="3 3"
                                            // ifOverflow="extendDomain"
                                            label={threshold_milli_g / 10}
                                        />
                                    ))}
                                <Line
                                    type="step"
                                    dataKey={(item) => item.data[group.index]}
                                    stroke={intToRGB(hashCode(group.name))}
                                    dot={dotRender(group)}
                                    hide={!group.checked}
                                    name={group.label}
                                    isAnimationActive={false}
                                />
                            </React.Fragment>
                        )
                    })}
                    {showBrush && (
                        <Brush
                            dataKey="created_at_timestamp"
                            tickFormatter={(unixTime) => dayjs.unix(unixTime).format('HH:mm:ss')}
                        />
                    )}
                    <Legend
                        onClick={(_, grpIndex) =>
                            onGroupToggle(groups[grpIndex].name, !groups[grpIndex].checked)
                        }
                    />
                    <Tooltip
                        labelFormatter={(unixTime) =>
                            dayjs.unix(unixTime).format('YYYY/MM/DD HH:mm:ss')
                        }
                        // TODO: trovare il modo di mostrare i threshold del gruppo
                        // formatter={(value, name, item, index, payload) => {
                        //     // value è il valore del punto (1642)
                        //     // name è il nome del gruppo ("Gruppo 1")
                        //     return [value, name]
                        // }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Grid>
    )
}
