import { useLocation, useParams } from "react-router-dom";
import { useQuerySismaToolUnprotectedEventData } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AlertBadge, alertLevelScore, eventSequentialId, MethodologyFab, MethodologySectionPaper, MethodologySectionTitle } from "../../components";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import React from "react";
import { SeismicMonitoringGraph } from "../../../../components/SeismicMonitoring/SeismicMonitoringGraph";

const useStyles = makeStyles(theme => ({
    outerContainer: {
        backgroundColor: theme.palette.background.page,
        width: '100%',
        height: 'fit-content',
        minHeight: '100%',
        // marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        
    },
    firstSection: {
        marginTop: theme.spacing(3),
    }
}))

const alertTableColumns = [
    {
        header: 'Fabbricato',
        key: 'procedureName',
        headerProps: {}
    },
    {
        header: 'Livello di allerta',
        key: 'alert_level',
        headerProps: {align: 'center'},
        cellProps: {align: 'center'}
    },
    {
        header: 'Classe C',
        key: 'building_class',
        headerProps: {align: 'center'},
        cellProps: {align: 'center'}
    },
    {
        header: 'Procedura',
        key: 'evaluation_type',
        headerProps: {}
    },
    {
        header: 'Azione suggerita',
        key: 'prescribed_action',
        headerProps: {}
    },
    {
        header: 'Limitazione di accesso',
        key: 'access_restriction',
        headerProps: {}
    },
    {
        header: 'Silenziato da',
        key: 'acknowledgedBy',
        headerProps: {}
    }
]



export default function SismaToolEventUnprotectedView({ match }) {
    const classes = useStyles()
    const { procedureId } = useParams()
    const { data, isLoading } = useQuerySismaToolUnprotectedEventData()
    const {pathname} = useLocation()
    
    const [event, alerts, procedureName, groups, graphData] = React.useMemo(() => {
        const event = data?.event || {}
        const procedureDetails = data?.sub_procedure_details || {}
        const alerts = (event?.alerts || []).map((alert) => {
            const acknowledgedByUser = (data?.user_details || {})[alert?.acknowledged_by?.$oid]
            if (alert?.access_restriction === 'chiusura_condizionale') alert.access_restriction = 'nessuna (condizionale)'
            return {
                ...alert,
                procedureName: procedureDetails[alert?.procedure_id?.$oid]?.name,
                acknowledgedBy: acknowledgedByUser && `${acknowledgedByUser?.first_name} ${acknowledgedByUser?.last_name}`
            }
        })
        const procedureName = procedureDetails[procedureId]?.name
        
        const seismicMonitoringConfiguration = (data?.seismic_monitoring_configurations || [undefined]).at(0)
        const groups = (seismicMonitoringConfiguration?.sensor_groups || []).map((group, index) => ({...group, index, checked: true, label: group?.name})).filter((group) => group.is_ground)
        const graphData = (data?.graph_data || [])
            .filter(plcState => plcState?.seismic_monitoring_configuration_id === seismicMonitoringConfiguration?.id)
            .map(plcState => ({
                created_at_timestamp: plcState?.created_at_timestamp,
                data: plcState?.document_content?.groups_state?.max_min_group_residual_norms_microg
            }))
        
        return [event, alerts, procedureName, groups, graphData]
        
        
    }, [data, procedureId])
    const eventDuration =
        event?.event_began_at_timestamp && event?.event_ended_at_timestamp
            ? `${Math.ceil(event?.event_ended_at_timestamp - event?.event_began_at_timestamp)}s`
            : 'N/A'
    
    return <Container
        direction={'column'}
        className={classes.outerContainer}
    >
        <div className={classes.firstSection}></div>
        {isLoading ? <LoadingSpinner /> : <Grid
            // className={classes.outerContainer}
            container
            alignItems="center"
            // justifyContent="flex-start"
            
            spacing={2}
        
        >
            
            <MethodologySectionPaper>
                <MethodologySectionTitle>
                    Evento del {eventSequentialId(event)} - {procedureName}
                </MethodologySectionTitle>
                <Typography align={'center'} >Livello allerta: <AlertBadge alertLevel={alertLevelScore({alert_level: event?.aggregate_alert_level})} />{event?.aggregate_alert_level}</Typography>
                <Typography align={'center'} >PGA max: {(event?.pga_max_microg / 10_000).toFixed(3)} %g</Typography>
                {!!eventDuration && <Typography align={'center'} >Durata: {eventDuration}</Typography>}
            </MethodologySectionPaper>
            
            {!!graphData?.length &&
                <MethodologySectionPaper>
                    <SeismicMonitoringGraph data={graphData} groups={groups} onGroupToggle={() => {
                    }} />
                
                </MethodologySectionPaper>
                
            }
            
                <MethodologySectionPaper>
                    <MethodologySectionTitle>
                        Edifici
                    </MethodologySectionTitle>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                {alertTableColumns.map(col => (
                                    <TableCell {...col.headerProps}>
                                        {col.header}
                                    </TableCell>
                                ))}
                            </TableHead>
                            <TableBody>
                                {alerts.map(alert => (
                                    <TableRow key={alert._id.$oid}>
                                        {alertTableColumns.map(col => (
                                            <TableCell {...(col?.cellProps || {})} size={'small'} key={col.key}>{alert[col.key]}</TableCell>
                                        ))}
                                    
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MethodologySectionPaper>
            
            
            
            {/*<MethodologySectionPaper>*/}
            {/*    <pre>{JSON.stringify(data, null, 2)}</pre>*/}
            {/*</MethodologySectionPaper>*/}
        
            {pathname.startsWith('/pub') && (<MethodologyFab onClick={() => {
                window.location.replace(pathname.replace('/pub', ''))
            }}>
                Login
            </MethodologyFab>)}
        
        </Grid>}
    </Container>
    
    
}