import { QueryClient } from 'react-query'
import axios from 'axios'
import { formatFiltersForBe } from './utils'
import { uninterceptedAxiosInstance } from './constants'
import _ from 'lodash'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})


export const loginQueries = {
    login: {
        name: 'login',
        fn: (data) => axios.post('/login', data),
    },
    loginAsAdmin: {
        name: 'loginAsAdmin',
        fn: () =>
            axios.post('/login', {
                email: 'admin@admin.com',
                password: 'admin',
            }),
    },
    loginAsMember: {
        name: 'loginAsMember',
        fn: () =>
            axios.post('/login', {
                email: 'member@member.com',
                password: 'member',
            }),
    },
    loginAsUser: {
        name: 'loginAsUser',
        fn: () =>
            axios.post('/login', {
                email: 'user@user.com',
                password: 'user',
            }),
    },
    logout: {
        name: 'logout',
        fn: () => axios.post('/logout'),
    },
}

export const modulesQueries = {
    getModules: {
        name: 'getModules',
        fn: async () => {
            const response = await axios.get('/modules')
            return response.data
        },
    },
    enableModule: {
        name: 'enableModule',
        fn: ({ companyId, module }) =>
            axios.put(`/companies/${companyId}/modules/${module}/enable`),
    },
    disableModule: {
        name: 'disableModule',
        fn: ({ companyId, module }) =>
            axios.put(`/companies/${companyId}/modules/${module}/disable`),
    },
}

export const proceduresQueries = {
    getProcedure: {
        name: 'getProcedure',
        fn: async (procedureId) => {
            const response = await axios.get(`/procedures/${procedureId}`)
            return response.data
        },
    },
    getProcedurePLC: {
        name: 'getProcedurePLC',
        fn: async (procedureId) => {
            const response = await axios.get(`/procedures/${procedureId}/plc`)
            return response.data
        },
    },
    assignPLCToProcedure: {
        name: 'assignPLCToProcedure',
        fn: async (data) => {
            const response = await axios.post(`/procedures/plc`, data)
            return response.data
        },
    },
    getProcedurePLCConfigurations: {
        name: 'getProcedurePLCConfigurations',
        fn: async (data) => {
            const response = await axios.get(
                `/procedures/${data['procedure_id']}/plc/${data['plc_id']}/configuration`
            )
            return response.data
        },
    },
    newProcedurePLCConfiguration: {
        name: 'newProcedurePLCConfiguration',
        fn: async (data) => {
            const response = await axios.post(
                `/procedures/${data['procedure_id']}/plc/${data['plc_id']}/configuration`,
                data
            )
            return response.data
        },
    },
    getProceduresAnalysisCatalog: {
        name: 'getProceduresAnalysisCatalog',
        fn: async (procedure_id) => {
            const response = await axios.get(`/procedures/${procedure_id}/analysis_catalog`)
            return response.data
        },
    },
    getProcedures: {
        name: 'getProcedures',
        fn: async (rules = {}, owner = undefined) => {
            const rulesAsArray = formatFiltersForBe(rules)

            const response = await axios.get(
                `/procedures?filters=${rulesAsArray}` + (owner ? `&company_id=${owner}` : '')
            )
            return response.data
        },
    },
    createProcedure: {
        name: 'createProcedure',
        fn: (procedureData) => axios.post('/procedures', procedureData),
    },
    updateProcedure: {
        name: 'updateProcedure',
        fn: ({ id, data }) => axios.put(`/procedures/${id}`, data),
    },
    getProcedureOwnerEnabledModules: {
        name: 'getProcedureOwnerEnabledModules',
        fn: ({ id }) => axios.get(`/procedures/${id}/owner_enabled_modules`),
    },
    updateProcedureName: {
        name: 'updateProcedureName',
        fn: ({ id, procedureName }) => axios.put(`/procedures/${id}/name`, procedureName),
    },
    extractProcedures: {
        name: 'extractProcedures',
        fn: ({ companyId, methodology }) => axios.get(`/export/${companyId}/${methodology}`),
    },
}

export const groupsQueries = {
    getGroups: {
        name: 'getGroups',
        fn: async () => {
            return []
            // commentato perché è troppo lenta e non serve a niente dal momento che ignoriamo i gruppi perché spaccano il layout della lista
            // const response = await axios.get(`/groups`)
            // return response.data
        },
    },
    createGroup: {
        name: 'createGroup',
        fn: ({ name, owner }) => axios.post('/groups', { name, owner }),
    },
    deleteGroup: {
        name: 'deleteGroup',
        fn: (groupId) => axios.delete(`/groups/${groupId}`),
    },
    addProcedureToGroup: {
        name: 'addProcedureToGroup',
        fn: (groupId, procedureId) =>
            axios.put(`/groups/${groupId}`, { procedure_id: procedureId }),
    },
}

export const accessControlQueries = {
    // which entities possess permissionTuple?
    getAcl: {
        name: 'getPermissionHolders',
        fn: async (permissionTuple) => {
            const response = await axios.get(
                `/access-control?permission=${permissionTuple.join(',')}`
            )
            return response.data
        },
    },
    grantPermission: {
        name: 'grantPermission',
        fn: (target_id, target_type, permission) =>
            axios.put(`/access-control?`, null, {
                params: {
                    target_id,
                    target_type,
                    permission: permission.join(','),
                },
            }),
    },
    revokePermission: {
        name: 'revokePermission',
        fn: (target_id, target_type, permission) =>
            axios.delete(`/access-control?`, {
                params: {
                    target_id,
                    target_type,
                    permission: permission.join(','),
                },
            }),
    },
}

export const companiesQueries = {
    getCompanies: {
        name: 'getCompanies',
        fn: async (permission = 'read') => {
            const response = await axios.get(`/companies?permission=${permission}`)
            return response.data
        },
    },
    getCompany: {
        name: 'getCompany',
        fn: async (companyId) => {
            const response = await axios.get(`/companies/${companyId}`)
            return response.data
        },
    },
    headCompany: {
        name: 'headCompany',
        fn: async (companyId) => {
            return await uninterceptedAxiosInstance.head(`/companies/${companyId}`)
        },
    },
    searchCompany: {
        name: 'searchCompany',
        fn: async (searchTerm) => {
            const response = await axios.get(`/companies?search=${searchTerm}`)
            // return response.data
            return Array.isArray(response.data) ? response.data : []
        },
    },
    createCompany: {
        name: 'createCompany',
        fn: (companyData) => axios.post('/companies', companyData),
    },
    updateCompany: {
        name: 'updateCompany',
        fn: (companyId, companyData) => axios.put(`/companies/${companyId}`, companyData),
    },
    deleteCompany: {
        name: 'deleteCompany',
        fn: (companyId) => axios.delete(`/companies/${companyId}`),
    },
    getMemberInvitations: {
        name: 'getMemberInvitations',
        fn: async (companyId) => {
            const response = await axios.get(`/company_invitations/${companyId}`, {
                params: { requestType: 'MEMBER' },
            })
            return response.data
        },
    },
    getReaderInvitations: {
        name: 'getReaderInvitations',
        fn: async (companyId) => {
            const response = await axios.get(`/company_invitations/${companyId}`, {
                params: { requestType: 'READER' },
            })
            return response.data
        },
    },
    inviteUser: {
        name: 'inviteUser',
        // requestType: 'MEMBER' o 'READER'
        fn: async ({ companyId, userInvitationEmail, requestType }) => {
            return axios.post(`/company_invitations/${companyId}`, {
                userInvitationEmail,
                requestType,
            })
        },
    },
    setCompanyExtraFields: {
        name: 'setCompanyExtraFields',
        fn: async (companyId, settings) =>
            axios.put(`/companies/${companyId}/settings/extra_fields`, settings),
    },
    setCompanyMethodologiesValues: {
        name: 'setCompanyMethodologiesValues',
        fn: async (companyId, settings) =>
            axios.put(`/companies/${companyId}/settings/methodologies`, settings),
    },
    handleRoles: {
        name: 'handleRoles',
        //valid actions: 'delete', 'mark_admin', 'mark_member', 'mark_reader'
        fn: ({ companyId, userId, action }) =>
            axios.patch(`/companies/${companyId}/user/${userId}`, { action }),
    },
    getCompanyProcedures: {
        name: 'getCompanyProcedures',
        fn: async ({ filters = {}, companyId }) => {
            const filtersAsArray = formatFiltersForBe(filters)
            const response = await axios.get(
                `/companies/${companyId}/procedures?filters=${filtersAsArray}`
            )
            return response.data
        },
    },
    handleCredits: {
        name: 'handleCredits',
        fn: async ({ action, company_id, methodology, procedure_id, quantity }) => {
            if (action === 'add') {
                axios.patch('/credit', {
                    action,
                    company_id,
                    methodology,
                    quantity,
                })
            } else if (action === 'use') {
                const response = await axios
                    .patch('/credit', {
                        action,
                        procedure_id,
                        methodology,
                    })
                    .catch((error) => {
                        throw error.response
                    })

                return response
            }
        },
    },

    getExtraFieldValues: {
        name: 'getCustomFilters',
        fn: async (companyId) => {
            const response = await axios.get(`companies/${companyId}/extra-field-values`)
            return response.data
        },
    },
}

export const levelsQueries = {
    getMitLevel0: {
        name: 'getMitLevel0',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/mit_guidelines/level0`
            )
            return response.data
        },
    },
    getMitLevel1: {
        name: 'getMitLevel1',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/mit_guidelines/level1`
            )
            return response.data
        },
    },
    getMitLevel3: {
        name: 'getMitLevel3',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/mit_guidelines/level3`
            )
            return response.data
        },
    },
    getMitLevel4: {
        name: 'getMitLevel4',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/mit_guidelines/level4`
            )
            return response.data
        },
    },
    getResistoPGAd: {
        name: 'getResistoPGAd',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/PGAd`
            )
            return response.data
        },
    },
    getResistoGndt: {
        name: 'getResistoGndt',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/gndt_level_2`
            )
            return response.data
        },
    },
    getResistoLocalVulnerabilities: {
        name: 'getResistoLocalVulnerabilities',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/local_vulnerabilities`
            )
            return response.data
        },
    },
    getResistoBuildingStatus: {
        name: 'getResistoBuildingStatus',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/building_status`
            )
            return response.data
        },
    },
    getResistoSopralluogo: {
        name: 'getResistoSopralluogo',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/inspection`
            )
            return response.data
        },
    },
    getResistoLivelli: {
        name: 'getResistoLivelli',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/resisto/floors`
            )
            return response.data
        },
    },
    updateLevel: {
        name: 'updateLevel',
        fn: (procedureId, methodologyName, level, formData) => {
            // TODO: Temporary fix while waiting to update react-hook-form

            const mapValuesDeep = (obj) => {
                if (_.isArray(obj)) {
                    return _.map(obj, (innerObj) => mapValuesDeep(innerObj))
                } else if (_.isPlainObject(obj)) {
                    if ('depot_id' in obj && 'data' in obj) {
                        delete obj.data
                        return obj
                    }
                    return _.mapValues(obj, (val) => mapValuesDeep(val))
                } else return obj
            }

            mapValuesDeep(formData)
            return axios.put(
                `/procedures/${procedureId}/methodologies/${methodologyName}/${level}`,
                formData
            )
        },
    },
}

export const snapshotsQueries = {
    getLastSnapshot: {
        name: 'getLastSnapshot',
        fn: async (procedureId, methodology, type) => {
            const response = await axios.get(`/snapshots/${procedureId}/${methodology}/${type}`)
            return response.data
        },
    },
    createSnapshot: {
        name: 'createSnapshot',
        fn: async ({
            procedureId,
            procedure_owner,
            methodology,
            eventuali_note,
            interventi_manutenzione_consigliati,
            type,
        }) => {
            const response = await axios.post(`/snapshots`, {
                procedure_id: procedureId,
                company_id: procedure_owner,
                methodology,
                eventuali_note,
                interventi_manutenzione_consigliati,
                type,
            })
            return response.data
        },
    },
    saveSignedDocument: {
        name: 'saveSignedDocument',
        fn: ({ snapshotId, file }) => {
            const formData = new FormData()
            formData.append('signed_document', file)
            return axios.put(`/snapshots/${snapshotId}`, formData)
        },
    },
    deleteSignedDocument: {
        name: 'deleteSignedDocument',
        fn: ({ snapshotId }) => {
            return axios.put(`/snapshots/${snapshotId}`, { delete_signed_document: true })
        },
    },
    getInspectionDelays: {
        name: 'getInspectionDelays',
        fn: async () => {
            const response = await axios.get('/snapshots/inspection_delays')
            return response.data
        },
    },
    markAsInspection: {
        name: 'markAsInspection',
        //snapshotId is required, if userDelay is undefined BE will assign default value
        fn: ({ snapshotId, monthsDelay, bridgeType, notes, isExtraordinary }) =>
            axios.patch(`/snapshots/${snapshotId}`, {
                months_delay: monthsDelay,
                bridge_type: bridgeType,
                notes: notes,
                is_extraordinary: isExtraordinary,
            }),
    },
    getInspections: {
        name: 'getInspections',
        fn: async ({ procedureId, methodology }) => {
            const response = await axios.get(
                `/snapshots/${procedureId}/${methodology}?inspections=true`
            )
            return response.data.reverse()
        },
    },
    getInspection: {
        name: 'getInspection',
        fn: async (inspectionId) => {
            const response = await axios.get(`/snapshots/inspections/${inspectionId}`)
            return response.data
        },
    },
}

export const CdAQueries = {
    calcCdAComplessiva: {
        name: 'calcCdAComplessiva',
        fn: async (procedureId) => {
            const response = await axios.get(
                `procedures/${procedureId}/methodologies/mit_guidelines/result`
            )
            return response.data
        },
    },
}

export const educationQueries = {
    getVideos: {
        name: 'getVideos',
        fn: async () => {
            const response = await axios.get('/education/videos')
            return response.data
        },
    },
    // currently logged user
    createSeenConfirmation: {
        name: 'createSeenConfirmation',
        fn: (videoId) => axios.post(`/education/videos/statistics/${videoId}`),
    },
    getProfileSeenConfirmations: {
        name: 'getProfileSeenConfirmations',
        fn: async () => {
            const response = await axios.get('/education/videos/statistics')
            return response.data
        },
    },
}

export const rolesQueries = {
    getRoles: {
        name: 'getRoles',
        fn: async () => {
            const response = await axios.get('/roles')
            return response.data
        },
    },
}

export const changelogQueries = {
    getChangelog: {
        name: 'getChangelog',
        fn: async () => {
            const response = await axios.get('/changelog')
            return response.data
        },
    },
}

export const usersQueries = {
    /***
     * use this query to fetch OTHER users, use profileQueries to get the current user
     */
    searchUser: {
        name: 'searchUser',
        fn: async (mail) => {
            const response = await axios.get(`/users`, {
                params: { search: mail },
            })
            return response.data
        },
    },
    getUser: {
        name: 'getUser',
        fn: async (userId) => {
            const response = await axios.get(`/users/${userId}`)
            return response.data
        },
    },
    createUser: {
        name: 'createUser',
        fn: (userData) => axios.post('/users', userData),
    },
    deleteUser: {
        name: 'deleteUser',
        fn: (userId) => axios.delete(`/users/${userId}`),
    },
    updateUser: {
        name: 'updateUser',
        fn: async ({ userId, userData }) => await axios.put(`/users/${userId}`, userData),
    },
    getUsers: {
        name: 'getUsers',
        fn: async () => {
            const response = await axios.get('/users')
            return response.data
        },
    },
}

export const profileQueries = {
    /***
     * use this query to fetch the current user, use usersQueries to get OTHER users
     */
    getUser: {
        name: 'getProfile',
        fn: async () => {
            const response = await axios.get(`/profile`)
            return response.data
        },
    },
    updateUser: {
        name: 'updateProfile',
        fn: async (userData) => {
            const response = await axios.put(
                `/profile`,
                {
                    ...userData,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            return response.data
        },
    },
    changePassword: {
        name: 'changePassword',
        fn: async (formPasswords) => {
            // password contains old and new one password
            const response = await axios.post('/change-password', {
                new_password: formPasswords.newPassword,
                old_password: formPasswords.oldPassword,
            })
            return response.data
        },
    },
    acceptBuiltiDocs: {
        name: 'acceptBuiltiDocs',
        fn: () => axios.put('/profile/accept', { builti_documents: true }),
    },
    acceptGdpr: {
        name: 'acceptGdpr',
        fn: () => axios.put('/profile/accept', { gdpr_accepted: true }),
    },
}

export const notificationQueries = {
    getNotifications: {
        name: 'getNotifications',
        fn: async () => {
            const response = await axios.get('/profile/notifications')
            return response.data
        },
    },
    markAllNotificationsRead: {
        name: 'markAllNotificationsRead',
        fn: () => axios.patch('/profile/notifications'),
    },
}

export const availableCompaniesQueries = {
    getAvailableCompanies: {
        name: 'getAvailableCompanies',
        fn: async () => {
            const response = await axios.get('/user/companies')
            return response.data
        },
    },
    getCompanyAnalytics: {
        name: 'getCompanyAnalytics',
        fn: async (companyId) => {
            const response = await axios.get(`/companies/${companyId}/analytics`)
            return response.data
        },
    },
    getMethodologyAnalytics: {
        name: 'getMethodologyAnalytics',
        fn: async ({ filters = {}, companyId, methodology }) => {
            const filtersAsArray = formatFiltersForBe(filters)
            const response = await axios.get(
                `/companies/${companyId}/analytics/${methodology}?filters=${filtersAsArray}`
            )
            return response.data
        },
    },

    getProceduresReportFile: {
        name: 'getProceduresReportFile',
        fn: async ({ filters = {}, companyId, methodology }) => {
            const filtersAsArray = formatFiltersForBe(filters)
            const response = await axios.get(
                `/export/${companyId}/${methodology}?filters=${filtersAsArray}`,
                { responseType: 'blob' }
            )
            return response.data
        },
    },

    updateProcedureCompany: {
        name: 'updateProcedureCompany',
        fn: async (procedureId, companyId) =>
            await axios.put('/procedures/' + procedureId + '/company/' + companyId),
    },
}

export const duplicateProcedureQueries = {
    createDuplicateProcedure: {
        name: 'createDuplicateProcedure',
        fn: async (procedureId, destination_company_id = null) =>
            await axios.post('/duplicate/procedure/' + procedureId, { destination_company_id }),
    },
}

export const dynamicTranslationQueries = {
    updateTranslation: {
        name: 'updateTranslation',
        fn: async (data) => await axios.put(`/locales`, data),
        // data : {
        //     language,
        //     namespace,
        //     key,
        //     value
        // }
    },
}

export const analysisQueries = {
    getAnalysisCatalog: {
        name: 'getAnalysisCatalog',
        fn: async () => {
            const response = await axios.get('/admin/analysis/catalog')
            return response.data
        },
    },
    createAnalysisCatalog: {
        name: 'createAnalysisCatalog',
        fn: async (data) => (await axios.post(`/admin/analysis/catalog`, data)).data,
    },
    createAnalysisCatalogVersion: {
        name: 'createAnalysisCatalogVersion',
        fn: async (data) => (await axios.post(`/admin/analysis/catalog/version`, data)).data,
    },
    saveAnalysisActiveVersion: {
        name: 'saveAnalysisActiveVersion',
        fn: async (data) =>
            await axios.patch(
                `/admin/analysis/catalog/${data.analysis_catalog_id}/${data.active_version_id}`
            ),
    },
}

export const fieldPcQueries = {
    getFieldPc: {
        name: 'getFieldPc',
        fn: async () => {
            const response = await axios.get('/admin/field_pc')
            return response.data
        },
    },
    createFieldPc: {
        name: 'createFieldPc',
        fn: async (data) => (await axios.post(`/admin/field_pc`, data)).data,
    },
}

export const plcQueries = {
    getPLC: {
        name: 'getPLC',
        fn: async () => {
            const response = await axios.get('/admin/plc')
            return response.data
        },
    },
    createPLC: {
        name: 'createPLC',
        fn: async (data) => (await axios.post(`/admin/plc`, data)).data,
    },
}

export const seismicMonitoringQueries = {
    createSeismicMonitoringConfiguration: {
        name: 'createSeismicMonitoringConfiguration',
        fn: (procedureId) => async (data) => {
            const resp = await axios.post(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/configuration`,
                data
            )
            return resp.data
        },
    },
    getSeismicMonitoringConfiguration: {
        name: 'getSeismicMonitoringConfiguration',
        fn: async (procedureId, configuration_id) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/configuration/${configuration_id}`
            )
            return response.data
        },
    },
    getSeismicMonitoringData: {
        name: 'getSeismicMonitoringData',
        fn: async (procedureId, start_isostring, end_isostring) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/data?datetime_interval_begin=${start_isostring}&datetime_interval_end=${end_isostring}`
            )
            return response.data
        },
    },
    getLatestSeismicMonitoringData: {
        name: 'getLatestSeismicMonitoringData',
        fn: async (procedureId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/data?get_latest=1`
            )
            return response.data
        },
    },
    getSeismicMonitoringEvent: {
        name: 'getSeismicMonitoringEvent',
        fn: async (procedureId, eventId) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/events/${eventId}`
            )
            return response.data
        },
    },
    getSeismicMonitoringEvents: {
        name: 'unmuted_only',
        fn: async (procedureId, unmuted_only) => {
            const response = await axios.get(
                `/procedures/${procedureId}/methodologies/seismic_monitoring/events?muted=${unmuted_only}`
            )
            return response.data
        },
    },
}
