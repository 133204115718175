import { useMutation, useQuery, useQueries } from 'react-query'
import { proceduresQueries, queryClient, seismicMonitoringQueries } from '../../../networking'
import useProcedureId from '../../useProcedureId'

export function useCreateSeismicMonitoringConfiguration() {
    const { procedureId } = useProcedureId()
    return useMutation(
        seismicMonitoringQueries.createSeismicMonitoringConfiguration.fn(procedureId),
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([
                    proceduresQueries.getProcedure.name,
                    procedureId,
                ])
                return data
            },
        }
    )
}

export function useQuerySeismicMonitoringConfiguration(configuration_id) {
    const { procedureId } = useProcedureId()
    return useQuery(
        [seismicMonitoringQueries.getSeismicMonitoringConfiguration.name, configuration_id],
        () =>
            seismicMonitoringQueries.getSeismicMonitoringConfiguration.fn(
                procedureId,
                configuration_id
            ),
        {
            enabled: !!configuration_id,
        }
    )
}

export function useQueriesSeismicMonitoringConfiguration(configuration_ids) {
    const { procedureId } = useProcedureId()
    return useQueries(
        configuration_ids.map((configuration_id) => ({
            queryKey: [
                seismicMonitoringQueries.getSeismicMonitoringConfiguration.name,
                configuration_id,
            ],
            queryFn: () =>
                seismicMonitoringQueries.getSeismicMonitoringConfiguration.fn(
                    procedureId,
                    configuration_id
                ),
        }))
    )
}

export function useQuerySeismicMonitoringData(startIsoString, endIsoString) {
    const { procedureId } = useProcedureId()
    return useQuery(
        [seismicMonitoringQueries.getSeismicMonitoringData.name, startIsoString, endIsoString],
        () => seismicMonitoringQueries.getSeismicMonitoringData.fn(procedureId, startIsoString, endIsoString),
        { enabled: !!startIsoString && !!endIsoString }
    )
}
export function useLatestQuerySeismicMonitoringData(refetchIntervalSeconds) {
    const { procedureId } = useProcedureId()
    return useQuery(
        [seismicMonitoringQueries.getLatestSeismicMonitoringData.name],
        () => seismicMonitoringQueries.getLatestSeismicMonitoringData.fn(procedureId),
        { refetchInterval: refetchIntervalSeconds * 1000 }
    )
}

export function useQuerySeismicMonitoringEvent(eventId) {
    const { procedureId } = useProcedureId()
    return useQuery(
        [seismicMonitoringQueries.getSeismicMonitoringEvent.name, eventId],
        () => seismicMonitoringQueries.getSeismicMonitoringEvent.fn(procedureId, eventId),
        { enabled: !!eventId }
    )
}

export function useQuerySeismicMonitoringEvents(unmuted_only) {
    const { procedureId } = useProcedureId()
    return useQuery([seismicMonitoringQueries.getSeismicMonitoringEvents.name, unmuted_only], () =>
        seismicMonitoringQueries.getSeismicMonitoringEvents.fn(procedureId, unmuted_only)
    )
}
