import { makeStyles, Paper, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/LoadingSpinner'
import { SeismicMonitoringGraphs } from '../../components/SeismicMonitoring/SeismicMonitoringGraphs'
import {
    useQuerySeismicMonitoringEvent
} from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
}))
const threshold_icon = ['🟢', '🟠', '🔴']

export const SeismicMonitoringEventDetail = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { eventId } = useParams()
    const { data: event = {}, isLoading } = useQuerySeismicMonitoringEvent(eventId)

    const begin = dayjs.unix(_.get(event, ['document_content', 'event_began_at_timestamp']))
    const end = dayjs.unix(_.get(event, ['document_content', 'event_ended_at_timestamp']))
    const duration = dayjs.duration(end.diff(begin))

    return (
        <MethodologyPage methodology="seismic_monitoring">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('seismic_monitoring_event'))}
                </Typography>
                {isLoading || _.isEmpty(event) ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Typography>
                            Event began at: {begin.format('DD/MM/YYYY HH:mm:ss.SSS')}
                        </Typography>
                        <Typography>
                            Event ended at: {end.format('DD/MM/YYYY HH:mm:ss.SSS')}
                        </Typography>
                        <Typography>Event duration: {duration.as('seconds')} seconds</Typography>
                        <Typography>
                            Event level: {_.get(event, ['document_content', 'event_severity'])}{' '}
                            {
                                threshold_icon[
                                    _.get(event, ['document_content', 'event_severity']) - 1
                                ]
                            }
                        </Typography>
                        <SeismicMonitoringGraphs
                            start={begin.subtract(30, 'seconds')}
                            end={end.add(30, 'seconds')}
                        />
                    </>
                )}
            </Paper>
        </MethodologyPage>
    )
}
