import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useQueriesSeismicMonitoringConfiguration,
    useQuerySeismicMonitoringData,
} from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import LoadingSpinner from '../LoadingSpinner'
import { SeismicMonitoringGraph } from './SeismicMonitoringGraph'

export const SeismicMonitoringGraphs = ({ startIsoString, endIsoString }) => {
    const { t } = useTranslation()
    const [checkedGroundGroups, setCheckedGroundGroups] = useState({})
    const [checkedNonGroundGroups, setCheckedNonGroundGroups] = useState({})

    const { data: seismicMonitoringData = {}, isLoading: isSeismicMonitoringDataLoading } =
        useQuerySeismicMonitoringData(startIsoString, endIsoString)

    const plc_states = seismicMonitoringData?.plc_states || []
    const data = plc_states.map((plc_state) => ({
        created_at_timestamp: plc_state.created_at_timestamp,
        data: plc_state.document_content.groups_state.max_min_group_residual_norms_microg,
    }))
    const seismic_monitoring_configuration_ids = (seismicMonitoringData.plc_states || []).reduce(
        (acc, state) => new Set([...acc, state.seismic_monitoring_configuration_id]),
        new Set()
    )
    const seismicMonitoringConfigurationResults = useQueriesSeismicMonitoringConfiguration(
        Array.from(seismic_monitoring_configuration_ids)
    )

    const [groundGroups, nonGroundGroups] = useMemo(() => {
        const ground = []
        const nonGround = []
        seismicMonitoringConfigurationResults.forEach((result) => {
            if (!result.isSuccess) return
            result.data.sensor_groups.forEach((grp, i) => {
                const group = {
                    ...grp,
                    label: grp.name,
                    index: i,
                    checked: checkedGroundGroups[grp.name],
                }
                if (grp.is_ground === true) {
                    ground.push(group)
                } else {
                    nonGround.push(group)
                }
            })
        })
        return [ground, nonGround]
    }, [seismicMonitoringConfigurationResults.map((r) => r.status).join(',')])

    useEffect(() => {
        setCheckedGroundGroups(Object.fromEntries(groundGroups.map((g) => [g.name, true])))
    }, [groundGroups])

    useEffect(() => {
        setCheckedNonGroundGroups(Object.fromEntries(nonGroundGroups.map((g) => [g.name, true])))
    }, [nonGroundGroups])

    const onGroundGroupToggle = (name, checked) => {
        setCheckedGroundGroups((old) => ({ ...old, [name]: checked }))
    }

    const onNonGroundGroupToggle = (name, checked) => {
        setCheckedNonGroundGroups((old) => ({ ...old, [name]: checked }))
    }

    const isLoading = useMemo(
        () =>
            isSeismicMonitoringDataLoading ||
            seismicMonitoringConfigurationResults.some((result) => result.isLoading),
        [isSeismicMonitoringDataLoading, seismicMonitoringConfigurationResults]
    )

    if (isLoading) return <LoadingSpinner />

    return (
        <Grid container>
            <Grid item xs={12} container direction="column">
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {t('Accelerazione al Suolo')}
                    </Typography>
                </Grid>
                <SeismicMonitoringGraph
                    data={data}
                    groups={groundGroups.map((g) => ({
                        ...g,
                        checked: checkedGroundGroups[g.name],
                    }))}
                    onGroupToggle={onGroundGroupToggle}
                    showBrush
                />
            </Grid>
            <Grid item xs={12} container direction="column">
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {t('Accelerazione ai Piani')}
                    </Typography>
                </Grid>
                <SeismicMonitoringGraph
                    data={data}
                    groups={nonGroundGroups.map((g) => ({
                        ...g,
                        checked: checkedNonGroundGroups[g.name],
                    }))}
                    onGroupToggle={onNonGroundGroupToggle}
                />
            </Grid>
        </Grid>
    )
}
