import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DateTimeRangePicker } from '../../components/DateRangePicker'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useQuerySeismicMonitoringData } from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'
import { createUseQueryArg } from "../../customHooks/createUseParam";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
}))

const useArgStart = createUseQueryArg({
    paramName: "argStart",
    setterName: "setArgStart",
    paramKey: "interval_begin",
})
const useArgEnd = createUseQueryArg({
    paramName: "argEnd",
    setterName: "setArgEnd",
    paramKey: "interval_end"
})

const threshold_icon = ['🟢', '🟠', '🔴']

export const SeismicMonitoringEvents = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    const { argStart, setArgStart } = useArgStart()
    const { argEnd, setArgEnd } = useArgEnd()

    const start = React.useMemo(() => !!argStart ? dayjs(argStart) : dayjs().subtract(1, 'hour'), [argStart])
    const end = React.useMemo(() => !!argEnd ? dayjs(argEnd) : dayjs(), [argEnd])

    const setStart = (value) => setArgStart(value.toISOString())
    const setEnd = (value) => setArgEnd(value.toISOString())

    const { data: seismicMonitoringData = {}, isLoading: isSeismicMonitoringDataLoading } =
        useQuerySeismicMonitoringData(argStart || start.toISOString(), argEnd || end.toISOString())

    const columns = useMemo(
        () => [
            {
                field: 'event_severity',
                headerName: t('Allerta'),
                valueFormatter: ({ value }) =>
                    `${threshold_icon[value - 1]} ${t('level')} ${value}`,
                align: 'center',
                sortable: false,
                width: 110,
            },
            {
                field: 'event_began_at_timestamp',
                headerName: t('start_datetime'),
                valueGetter: ({ value }) => dayjs.unix(value),
                valueFormatter: ({ value }) => value.format('YYYY/MM/DD HH:mm:ss'),
                width: 160,
            },
            {
                field: 'event_ended_at_timestamp',
                headerName: t('duration'),
                valueGetter: ({ row }) => {
                    const begin = dayjs.unix(row.event_began_at_timestamp)
                    const end = dayjs.unix(row.event_ended_at_timestamp)
                    const duration = dayjs.duration(end.diff(begin))
                    return duration.as('seconds')
                },
                valueFormatter: ({ value }) => value + 's',
                flex: 1,
            },
            {
                field: 'sequential_id',
                headerName: ' ',
                renderCell: ({ row }) => {
                    return (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => history.push('./events/' + row.id)}
                        >
                            {t('details')}
                        </Button>
                    )
                },
                sortable: false,
            },
        ],
        [t, history]
    )

    return (
        <MethodologyPage methodology="seismic_monitoring" maxWidth="lg">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('seismic_monitoring_events'))}
                </Typography>
                <Box marginY={2}>
                    <DateTimeRangePicker dateTimeRange={{ start, end, setStart, setEnd }} />
                </Box>
                {isSeismicMonitoringDataLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Box height={500}>
                        <DataGrid
                            columns={columns}
                            rows={seismicMonitoringData.events.map((e) => ({
                                ...e.document_content,
                                id: e.id,
                            }))}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                        />
                    </Box>
                )}
            </Paper>
        </MethodologyPage>
    )
}
