import { Card, CardContent, CardHeader, Chip, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SyncStatusChip from '../SyncStatusChip'

export default function SeismicMonitoringConfigurationDetails({ configuration }) {
    const { t } = useTranslation()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SyncStatusChip synchronizedDocument={configuration} />
            </Grid>
            {configuration.sensor_groups.map((sensorGroup) => (
                <Grid item xs={6} md={4} key={sensorGroup.name}>
                    <Card>
                        <CardHeader
                            title={sensorGroup.name}
                            avatar={
                                <Chip label={t(sensorGroup.is_ground ? 'ground' : 'no_ground')} />
                            }
                        />
                        <CardContent>
                            <Typography>
                                {t('sensors')}: {sensorGroup.sensors.join(', ')}
                            </Typography>
                            <Typography>
                                {t('thresholds')}: {sensorGroup.thresholds_milli_g.join(', ')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}
