import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    rootNotSync: {
        backgroundColor: theme.palette.error.light,
    },
    rootPartialSync: {
        backgroundColor: theme.palette.secondary.light,
    },
    rootFullSync: {
        backgroundColor: theme.palette.success.light,
    },
}))
const syncLabelMapping = {
    SUCCESS: 'Synced',
    PARTIAL: 'Partially synced',
    ERROR: 'Not synced',
}
const syncRootClassMapping = {
    SUCCESS: 'rootFullSync',
    PARTIAL: 'rootPartialSync',
    ERROR: 'rootNotSync',
}
export default function SyncStatusChip({ synchronizedDocument }) {
    const classes = useStyles()
    const { t } = useTranslation()

    const syncStatus = useMemo(() => {
        const sync_count = synchronizedDocument.sync_targets.filter(
            (target) => synchronizedDocument.sync_received_at[target] !== undefined
        ).length
        if (sync_count === synchronizedDocument.sync_targets.length) return 'SUCCESS'
        else if (sync_count === 0) return 'ERROR'
        else return 'PARTIAL'
    }, [synchronizedDocument])

    return (
        <Chip
            label={t(syncLabelMapping[syncStatus])}
            classes={{
                root: classes[syncRootClassMapping[syncStatus]],
            }}
            variant="outlined"
        />
    )
}
