import { useHistory, useLocation } from "react-router-dom";
import React from "react";

export function createUseQueryArg({ paramName, setterName, paramKey }) {
    return () => {
        const location = useLocation()
        const history = useHistory()
        const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
        const paramValue = searchParams.get(paramKey)
        const setParam = React.useCallback((newValue) => {
            if (newValue === undefined) {
                searchParams.delete(paramKey)
            } else {
                searchParams.set(paramKey, newValue)
            }
            
            history.replace({...location, search: searchParams.toString()})
        }, [searchParams, history, location ])
        return {
            [setterName]: setParam,
            [paramName]: paramValue ?? '',
            [paramKey]: paramKey
        }
    }
}

export const usePlcId = createUseQueryArg({
    paramName: "plcId",
    setterName: "setPlcId",
    paramKey: "plc_id"
})
export const useProcedurePlcConfigurationId = createUseQueryArg({
    paramName: "procedurePlcConfigurationId",
    setterName: "setProcedurePlcConfigurationId",
    paramKey: "procedure_plc_configuration_id"
})
export const useAnalysisId = createUseQueryArg({
    paramName: "analysisId",
    setterName: "setAnalysisId",
    paramKey: "analysis_id"
})
export const useResultId = createUseQueryArg({
    paramName: "resultId",
    setterName: "setResultId",
    paramKey: "result_id"
})