import React,{ Suspense, useRef, useEffect } from 'react';
import {alertLevelScore, useMethodologyStyles} from "../../components";
import { MethodologyAccordionSummary, MethodologySectionPaper, MethodologySectionTitle } from "../../components";
import { useTranslation } from "react-i18next";
import {Accordion, AccordionSummary, Grid} from "@material-ui/core";
import { useQueriesProcedures, useQueryProcedure } from "../../../../customHooks/queryHooks/procedureQueries";
import useProcedureId from "../../../../customHooks/useProcedureId";
import { useQuerySismaToolData, useQuerySismaToolEvents
} from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { DateTimeRangePicker } from "../../../../components/DateRangePicker";
import { createUseQueryArg } from "../../../../customHooks/createUseParam";
import dayjs from "dayjs";
import PiantaSensori2d from "../../../../assets/seismic_monitoring_schemas/pianta_sensori_2d.png";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, useTexture } from '@react-three/drei';
import { eventLevelScore} from "../../components";
import{AlertBadge} from "../../components";

const data = [
    { x: 1, y: 4 },
    { x: 2, y: 2 },
    { x: 3, y: 9 },
    { x: 4, y: 5 }
];
const CartesianChart = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw axes
        ctx.beginPath();
        ctx.moveTo(50, 250);
        ctx.lineTo(350, 250);
        ctx.moveTo(50, 250);
        ctx.lineTo(50, 50);
        ctx.stroke();

        // Draw points and lines
        ctx.beginPath();
        data.forEach((point, index) => {
            const x = 50 + point.x * 50;
            const y = 250 - point.y * 20;
            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
            ctx.arc(x, y, 3, 0, 2 * Math.PI);
        });
        ctx.stroke();
    }, []);

    return (
        <div className="flex justify-center items-center p-4">
            <canvas ref={canvasRef} width={400} height={300} className="border" />
        </div>
    );
};




const useArgStart = createUseQueryArg({
    paramName: "argStart",
    setterName: "setArgStart",
    paramKey: "interval_begin",
})
const useArgEnd = createUseQueryArg({
    paramName: "argEnd",
    setterName: "setArgEnd",
    paramKey: "interval_end"
})


const Show3dModel =({AlertLevel})=> {
    const {scene, materials} = useGLTF('/Test3D_Fusaro/Mappa_test.gltf')
    const texture = useTexture('/Test3D_Fusaro/GOOGLE_SAT_WM.png');
    const color = {
        nessuna_allerta: '#00ff00',
        allerta_0: '#00ff00',
        allerta_1: 'yellow',
        allerta_2: 'red',
    }[AlertLevel] || 'grey'

    texture.flipY = false;

    materials["Material.001"].color.set(color);
    materials["Material.002"].color.set(color);
    materials["Material.003"].color.set(color);
    materials["Material.004"].color.set(color);
    materials["Material.005"].color.set(color);
    materials["Material.006"].color.set(color);
    materials["Material.007"].color.set(color);
    materials["Material.008"].color.set(color);
    materials["Material.009"].color.set(color);
    materials["Material.010"].color.set(color);
    materials["Material.011"].color.set(color);

    if (materials && texture) {
        materials.mappazza.map = texture;
        materials.mappazza.needsUpdate = true;
    }

    return <primitive object={scene} scale={1}/>
}



function SemaforoSismaTool({alertLevel}){
    const backgroundColor = {
        nessuna_allerta: 'green',
        allerta_0: 'green',
        allerta_1: 'yellow',
        allerta_2: 'red',
    }[alertLevel] || 'grey'

    return(<>
        <Grid>
            <div style={{backgroundColor:'#dcdcdc',
                borderRadius:'10%',
                height:'70px',
                width:'60px',
                display:'flex',
                alignItems:'center',
            }}>

                <div style={{backgroundColor, borderRadius:'100%',height:'50px',width:'50px',margin:'0px auto',}} className={'green'}/>
                {/*<AlertBadge alertLevel={eventLevelScore('livello_0')} />*/}
            </div>

        </Grid>
    </>)
}



export default function MyApp() {
    const [viewMode, setviewMode] = React.useState('2d')

    const { argStart, setArgStart } = useArgStart()
    const { argEnd, setArgEnd } = useArgEnd()
    const start = React.useMemo(() => !!argStart ? dayjs(argStart) : dayjs().subtract(1, 'hour'), [argStart])
    const end = React.useMemo(() => argEnd ? dayjs(argEnd) : dayjs(), [argEnd])
    const setStart = (value) => setArgStart(value.toISOString())
    const setEnd = (value) => setArgEnd(value.toISOString())
    
    const { data: procedure } = useQueryProcedure()
    const resultExtraInfo = procedure?.methodologies?.hexo?.workspace?.result_extra_info


    return (
        <>
            <MethodologySectionPaper>
                <MethodologySectionTitle><h2>Telemetria</h2></MethodologySectionTitle>
                <DateTimeRangePicker dateTimeRange={{ start, end, setStart, setEnd }} />
                <MethodologySectionPaper>
                    <h2>Grafici</h2>
                    <CartesianChart/>
                </MethodologySectionPaper>
                <MethodologySectionPaper>
                    <h2>Struttura</h2>
                    <Grid>
                        <Grid
                        style={{backgroundColor:'#dcdcdc',
                            borderRadius:'20%',
                            height:'400px',
                            width:'500px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            }}>
                        {viewMode=='2d'?
                            <div>
                                <img src={PiantaSensori2d} alt="PiantaSensori2d" height={300} />
                            </div>:
                                <div>
                                    <Suspense fallback={<div>Caricamento modello...</div>}>
                                    <Canvas camera={{ position: [200,200,200], fov: 50,}}
                                    style={{ height:'300px',width:'400px', borderRadius:'20%',}}>
                                        <ambientLight intensity={0.5} />
                                        <directionalLight position={[5, 5, 5]} intensity={1} />
                                        <Show3dModel AlertLevel={resultExtraInfo?.aggregate_status?.alert_level}/>
                                        <OrbitControls />
                                    </Canvas>
                                        </Suspense>
                                </div>}
                        </Grid>
                        <button className={'planimetria2d'} onClick={() => setviewMode('2d')} style={{backgroundColor:'#ffffff',
                                                                                                                    borderRadius:'20%',
                                                                                                                    color:'#00000',
                                                                                                                    height:'40px',
                                                                                                                    width:'60px',
                                                                                                                    fontWeight:'bold',
                                                                                                                    fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',}}>
                            2d
                        </button>
                        <button className={'planimetria3d'} onClick={() => setviewMode('3d')} style={{backgroundColor:'#ffffff',
                                                                                                                    borderRadius:'20%',
                                                                                                                    color:'#00000',
                                                                                                                    height:'40px',
                                                                                                                    width:'60px',
                                                                                                                    fontWeight:'bold',
                                                                                                                    fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',}}>
                            3d
                        </button>
                    </Grid>
                </MethodologySectionPaper>
                <MethodologySectionPaper>
                    <h2>Semafori</h2>
                    <SemaforoSismaTool alertLevel={resultExtraInfo?.aggregate_status?.alert_level}/>
                    {/*{resultExtraInfo.aggregate_status.alert_level}*/}
                    {/*{<pre>{JSON.stringify(resultExtraInfo, null, 2)}</pre>}*/}
                </MethodologySectionPaper>
            </MethodologySectionPaper>
        </>
    )
}
