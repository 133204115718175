import { useMutation, useQuery } from "react-query";
import useProcedureId from "../../useProcedureId";
import axios from "axios";
import { queryClient } from "../../../networking";
import { useSnackBarStore } from "../../../stateManagement";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useMethodology from "../../useMethodology";

export function useQuerySismaToolAlerts() {
    const { procedureId } = useProcedureId()
    
    return useQuery(
        ['getSismaToolAlerts', procedureId],
        async () => (await axios.get(`/procedures/${procedureId}/methodologies/hexo/alerts`)).data,
        {
            enabled: !!procedureId
        }
        
    )
    
}

export function useQuerySismaToolStaff() {
    const { procedureId } = useProcedureId()
    
    return useQuery(
        ['getSismaToolStaff', procedureId],
        async () => {
            const response = await axios.get(`/procedures/${procedureId}/methodologies/hexo/staff`)
            const { staff, user_details } = await response.data
            staff.hse = (staff?.hse || []).map(_id => user_details[_id]).filter(user => !!user)
            staff.rspp = (staff?.rspp || []).map(_id => user_details[_id]).filter(user => !!user)
            staff.externals = (staff?.externals || []).map(_id => user_details[_id]).filter(user => !!user)
            return staff
        },
        {
            enabled: !!procedureId,
        }
    )
}

export function useQuerySismaToolEvents() {
    const { procedureId } = useProcedureId()
    const { methodology } = useMethodology()
    
    return useQuery(
        ['getSismaToolEvents', procedureId],
        async () => (await axios.get(`/procedures/${procedureId}/methodologies/hexo/events`)).data.sort((a, b) => b.event_began_at_timestamp - a.event_began_at_timestamp),
        {
            enabled: (!!procedureId) && (methodology === 'hexo')
        }
    )
}
export function useQuerySismaToolEvent({ eventId }) {
    const { procedureId } = useProcedureId()
    const { methodology } = useMethodology()
    
    return useQuery(
        ['getSismaToolEvents', procedureId],
        async() => (await axios.get(`/procedures/${procedureId}/methodologies/hexo/events/${eventId}`)).data,
        {
            enabled: (!!procedureId) && (!!eventId) && (methodology === 'hexo')
        }
    )
}

export function useCreateSismaToolEvent() {
    const { procedureId } = useProcedureId()
    const showSnackBar = useSnackBarStore((state) => state.show);
    const { t } = useTranslation()
    return useMutation(
        (eventData) => {
            eventData.procedure_id = procedureId
            return axios.post(
                `/procedures/${procedureId}/methodologies/hexo/events`,
                eventData
            )
        },

        {
            onSuccess: () => {
                queryClient.invalidateQueries(['getSismaToolEvents', procedureId]).then(
                    showSnackBar({message: t('event_created'), severity: 'success'})
                )
            },
            onError: () => showSnackBar({message: t('generic_error'), severity: 'error'})
        }
    )
}

export function useAcknowledgeSismaToolAlert() {
    const { procedureId } = useProcedureId()
    const showSnackBar = useSnackBarStore((state) => state.show);
    const { t } = useTranslation()
    
    return useMutation(
        (alert) => {
            return axios.patch(
                `/procedures/${procedureId}/methodologies/hexo/alerts/${alert?.id}?action=acknowledge`
            )
        },
        
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['getSismaToolAlerts', procedureId]).then(
                    showSnackBar({message: t('alert_acknowledged'), severity: 'success'})
                )
            },
            onError: () => showSnackBar({message: t('generic_error'), severity: 'error'})
        }
    )
}

export function useAcknowledgeSismaToolAlerts() {
    const { procedureId } = useProcedureId()
    const showSnackBar = useSnackBarStore((state) => state.show);
    const { t } = useTranslation()
    
    return useMutation(
        (subProcedureId) => {
            return axios.patch(
                `/procedures/${procedureId}/methodologies/hexo/alerts?action=acknowledge&sub_procedure_id=${subProcedureId}`
            )
        },
        
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['getSismaToolAlerts', procedureId]).then(
                    showSnackBar({message: t('alert_acknowledged'), severity: 'success'})
                )
            },
            onError: () => showSnackBar({message: t('generic_error'), severity: 'error'})
        }
    )
}

export function useMutateSismaToolStaff({action}) {
    const { procedureId } = useProcedureId()
    const showSnackBar = useSnackBarStore((state) => state.show);
    const { t } = useTranslation()
    
    return useMutation(
        async ({ userId }) => (await axios.patch(`/procedures/${procedureId}/methodologies/hexo/staff/${action}/${userId}`)).data,
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['getSismaToolStaff', procedureId])
                showSnackBar({message: 'Operazione effettuata con successo', severity: 'success'})
            },
            onError: () => showSnackBar({message: t('generic_error'), severity: 'error'})
        }
    )
}

export function useQuerySismaToolUnprotectedProcedureData() {
    const { procedureId } = useParams()
    return useQuery(
        ['getSismaToolUnprotectedProcedureData', procedureId],
        async () => (await axios.get(`/methodologies/hexo/procedures/${procedureId}`)).data,
        {
            enabled: !!procedureId,
            refetchInterval: 10_000
        }
    )
}

export function useQuerySismaToolUnprotectedEventData() {
    const { procedureId, eventId } = useParams()
    return useQuery(
        ['getSismaToolUnprotectedEventData', eventId],
        async () => (await axios.get(`/methodologies/hexo/procedures/${procedureId}/events/${eventId}`)).data,
        {
            enabled: (!!procedureId) && (!!eventId)
        }
    )
}
