import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { Grid, Typography, Button, IconButton, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import WorkIcon from '@material-ui/icons/Work'
import SearchBar from '../FormItems/SearchBar'
import SearchDialog from '../Dialogs/SearchDialog'
import ProceduresList from './ProceduresList'
import FilterIcon from '@material-ui/icons/FilterList'
import LoadingSpinner from '../LoadingSpinner'
import AppTooltip from '../AppTooltip'
import { useCompanyStore, useProceduresPanelStore } from '../../stateManagement'
import { CustomQuickFiltersChip } from '../CustomQuickFiltersChip'
import { ClearAll } from '@material-ui/icons'
import { structureTypes } from '../../constants'
import { useStoredFilters } from '../../customHooks/storedFiltersContext'
import {
    useQueryAllProcedures,
    useQueryFilteredProcedures,
} from '../../customHooks/queryHooks/procedureQueries'
import AdvancedSearchTool from '../AdvancedSearchTool'

const useStyles = makeStyles((theme) => ({
    top: {
        margin: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: 'auto',
    },
    tools: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: 20,
        padding: theme.spacing(0.5),
        width: 'auto',
        position: 'relative',
    },
    nonBiTools: {
        background: theme.palette.background.paper,
        marginTop: theme.spacing(),
    },
    procedures: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        flex: 1,
    },
    advancedFiltersContainer: {
        padding: theme.spacing(2),
        height: '90%',
    },
}))
const emptyArray = Object.freeze([])
const fuseOptions = { keys: ['name'], threshold: 0.5 }

export default function ProceduresPanel({ BIinterface = false }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    useQueryAllProcedures()
    const { data: filteredProcedures = emptyArray, isFetching: loading } =
        useQueryFilteredProcedures()

    const [filters, resetAllFilters] = useStoredFilters((state) => [
        state.filters,
        state.resetAllFilters,
    ])
    const writeCompanies = useCompanyStore((state) => state.writeCompanies)

    const [searchInput, setSearchInput] = React.useState('')
    const [isCreateProcedureDialogOpen, setIsCreateProcedureDialogOpen] = React.useState(false)
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = React.useState(false)

    const [totCount, listedProcedures, setListedProcedures] = useProceduresPanelStore((state) => [
        state.totCount,
        state.listedProcedures,
        state.setListedProcedures,
    ])

    function goToCreateProcedure(companyId) {
        history.push('/procedures/init', { companyId })
    }

    const fuse = React.useMemo(() => new Fuse([], fuseOptions), [])

    React.useEffect(() => {
        if (searchInput) {
            fuse.setCollection(filteredProcedures)
            setListedProcedures(fuse.search(searchInput).map((p) => p.item))
        } else {
            setListedProcedures(filteredProcedures)
        }
    }, [filteredProcedures, searchInput, fuse, setListedProcedures])

    const isFiltersActive = Object.keys(filters?.rules || {}).length !== 0
    return (
        <>
            {!BIinterface && (
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.top}
                >
                    <Grid item>
                        <Typography variant="h4" component="h1">
                            {t('pratiche')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {writeCompanies.length ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddIcon />}
                                onClick={() => setIsCreateProcedureDialogOpen(true)}
                            >
                                {t('nuova_pratica')}
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                className={`${classes.tools} ${!BIinterface && classes.nonBiTools}`}
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                {!BIinterface && (
                    <Grid item container spacing={1} direction={'row'}>
                        <CustomQuickFiltersChip
                            path={'type'}
                            possibleValues={structureTypes}
                            translateLabels={true}
                            placeholder={`${t('type')}`}
                        />
                    </Grid>
                )}
                <SearchBar
                    item
                    onIconClick={() => {
                        setIsCreateProcedureDialogOpen(true)
                    }}
                    justifyContent="flex-end"
                    input={searchInput}
                    setInput={setSearchInput}
                    useLabel={BIinterface}
                    BIinterface={BIinterface}
                    endAdornment={() => (
                        <>
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {isFiltersActive
                                            ? `${t('advanced_search')} - ${t(
                                                  'some_filters_are_active'
                                              )}!`
                                            : t('advanced_search')}
                                    </Typography>
                                }
                            >
                                <IconButton onClick={() => setIsAdvancedSearchOpen(true)}>
                                    <FilterIcon />
                                </IconButton>
                            </AppTooltip>
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {t('clear_all_filters')}
                                    </Typography>
                                }
                            >
                                <span>
                                    <IconButton
                                        onClick={resetAllFilters}
                                        disabled={!isFiltersActive}
                                    >
                                        <ClearAll />
                                    </IconButton>
                                </span>
                            </AppTooltip>
                        </>
                    )}
                />
                <Grid item>
                    <Typography
                        // component={'h1'}
                        style={{ fontSize: '2em', marginRight: 5 }}
                        // color={'secondary'}
                    >
                        {/*{`${filteredProcedures.length}/${proceduresTotCount}`}*/}
                        {listedProcedures.length}/{totCount} {t('search_results')}
                    </Typography>
                    {/*<Typography variant={'caption'}>{t('search_results')}</Typography>*/}
                </Grid>
            </Grid>
            <Grid item className={classes.procedures}>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <ProceduresList
                        procedures={listedProcedures}
                        getProcedures={() => filteredProcedures}
                    />
                )}
            </Grid>

            <SearchDialog
                open={isCreateProcedureDialogOpen}
                setIsOpen={setIsCreateProcedureDialogOpen}
                title="pratica_selezione_company_titolo"
                subtitle="pratica_selezione_company_testo"
                data={writeCompanies}
                handleResultClick={goToCreateProcedure}
                icon={<WorkIcon />}
            />

            <Dialog
                open={isAdvancedSearchOpen}
                onClose={() => setIsAdvancedSearchOpen(false)}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    className: classes.advancedFiltersContainer,
                    elevation: 0,
                    variant: 'outlined',
                }}
            >
                <AdvancedSearchTool onClose={() => setIsAdvancedSearchOpen(false)} />
            </Dialog>
        </>
    )
}
