import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useQueryProcedurePlcConfigurations } from '../../customHooks/queryHooks/methodologyQueries/dynamicsQueries'
import {
    useLatestQuerySeismicMonitoringData,
    useQuerySeismicMonitoringConfiguration,
} from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import { useQueryProcedure } from '../../customHooks/queryHooks/procedureQueries'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'

import PiantaSensori2d from '../../assets/seismic_monitoring_schemas/pianta_sensori_2d.png'
import PiantaSensori3d from '../../assets/seismic_monitoring_schemas/pianta_sensori_3d.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
}))

export const SeismicMonitoringStatus = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    const { data: procedure, isLoading: isProcedureLoading } = useQueryProcedure()

    const {
        data: seismicMonitoringConfiguration,
        isLoading: isSeismicMonitoringConfigurationLoading,
    } = useQuerySeismicMonitoringConfiguration(
        procedure?.methodologies?.seismic_monitoring?.seismic_monitoring_configuration_id
    )

    const { data: procedurePlcConfigurations, isLoading: isPlcConfigurationsLoading } =
        useQueryProcedurePlcConfigurations(
            seismicMonitoringConfiguration?.procedure_plc_configuration_id
        )

    const { data: latestSeismicMonitoringData, isLoading: isLatestSeismicMonitoringDataLoading } =
        useLatestQuerySeismicMonitoringData(
            procedurePlcConfigurations?.[0].plc_configuration.keepalive_seconds || 5
        )

    const isLoading = useMemo(
        () =>
            isProcedureLoading ||
            isSeismicMonitoringConfigurationLoading ||
            isPlcConfigurationsLoading ||
            isLatestSeismicMonitoringDataLoading,
        [
            isProcedureLoading,
            isSeismicMonitoringConfigurationLoading,
            isPlcConfigurationsLoading,
            isLatestSeismicMonitoringDataLoading,
        ]
    )

    // const { data: events, isLoading } = useQuerySeismicMonitoringEvents(true)
    // const {
    //     data: [plcConfiguration],
    //     isLoading: isPlcConfigurationsLoading,
    // } = useQueryProcedurePlcConfigurations()
    // const [worst_severity_ground, worst_severity_floors] = useMemo(() => {
    //     if (!events || !Object.keys(events).length) return []
    //     const result = events.reduce(
    //         (acc, event) => {
    //             return {
    //                 ground: Math.max(acc.ground, event.document_content.severity_ground),
    //                 floors: Math.max(acc.floors, event.document_content.severity_floors),
    //             }
    //         },
    //         { ground: 0, floors: 0 }
    //     )
    //     return [result.ground, result.floors]
    // }, [events])

    const keepaliveSeconds = procedurePlcConfigurations?.[0].plc_configuration.keepalive_seconds
    // const margin_s = 5 * 60 // 5 minutes
    const margin_s = 30
    const now = dayjs()
    const last_plc_state_created_at = dayjs.unix(
        latestSeismicMonitoringData?.last_plc_state.created_at_timestamp
    )
    const delay_s = dayjs.duration(now.diff(last_plc_state_created_at)).as('seconds')
    const is_network_ok = delay_s < keepaliveSeconds + margin_s

    const is_sensors_ok =
        latestSeismicMonitoringData?.last_plc_state.document_content.sensors_state.x_residuals_microg.filter(
            (g) => g !== 0
        ).length > 0
    const is_ok = is_network_ok && is_sensors_ok

    console.info('is_sensors_ok', is_sensors_ok)
    console.info('is_network_ok', is_network_ok)
    console.info('is_ok', is_ok)

    return (
        <MethodologyPage methodology="seismic_monitoring" maxWidth="lg">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('seismic_monitoring'))}
                </Typography>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                                <img src={PiantaSensori2d} alt="PiantaSensori2d" height={300} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                                <img src={PiantaSensori3d} alt="PiantaSensori3d" height={300} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography>Accelerazione al suolo</Typography>
                                <div
                                    style={{
                                        border: '1px solid white',
                                        backgroundColor: 'black',
                                        width: 'fit-content',
                                        padding: 15,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 45,
                                            height: 45,
                                            borderRadius: '100%',
                                            backgroundColor:
                                                is_network_ok !== is_sensors_ok
                                                    ? '#385472'
                                                    : 'transparent',
                                            border: '2px solid white',
                                            borderColor:
                                                is_network_ok !== is_sensors_ok
                                                    ? '#385472'
                                                    : 'white',
                                            marginBottom: 10,
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: 45,
                                            height: 45,
                                            borderRadius: '100%',
                                            backgroundColor:
                                                !is_ok && is_network_ok === is_sensors_ok
                                                    ? 'white'
                                                    : 'black',
                                            border: '2px solid white',
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography>Accelerazione ai piani</Typography>
                                <div
                                    style={{
                                        border: '1px solid white',
                                        backgroundColor: 'black',
                                        width: 'fit-content',
                                        padding: 15,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 45,
                                            height: 45,
                                            borderRadius: '100%',
                                            backgroundColor:
                                                is_network_ok !== is_sensors_ok
                                                    ? '#385472'
                                                    : 'transparent',
                                            border: '2px solid white',
                                            borderColor:
                                                is_network_ok !== is_sensors_ok
                                                    ? '#385472'
                                                    : 'white',
                                            marginBottom: 10,
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: 45,
                                            height: 45,
                                            borderRadius: '100%',
                                            backgroundColor:
                                                !is_ok && is_network_ok === is_sensors_ok
                                                    ? 'white'
                                                    : 'black',
                                            border: '2px solid white',
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Paper>
        </MethodologyPage>
    )
}
