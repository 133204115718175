import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { MethodologyButton } from "../../components";
import { useTranslation } from "react-i18next";
import { useAcknowledgeSismaToolAlert } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";



export default function AcknowledgeDialog({controls}) {
    const { t } = useTranslation()
    const { isOpen, close, state } = controls
    const procedureData = state?.procedureData
    const alertData = state?.alertData
    
    const { mutateAsync: acknowledgeAlert } = useAcknowledgeSismaToolAlert()
    
    return (
        <Dialog open={isOpen && !!procedureData} onClose={close} fullWidth={true} maxWidth="md">
            <DialogTitle>
                {t('acknowledge')}
            </DialogTitle>
            <DialogContent>
                Stai confermando di aver preso visione dell'azione suggerita per il fabbricato {procedureData?.name}:
                {/*<pre>{JSON.stringify(alertData, null, 2)}</pre>*/}
            </DialogContent>
            <DialogActions>
                <MethodologyButton variant={'contained'} color={'primary'} onClick={close}>
                    Annulla
                </MethodologyButton>
                <MethodologyButton onClick={() => acknowledgeAlert(alertData).then(() => close())}>
                    Conferma
                </MethodologyButton>
            </DialogActions>
        </Dialog>
    )
}


