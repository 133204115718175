import { useUserProfileStore } from "../../../../stateManagement";
import { useQuerySismaToolStaff } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { useQueryProcedure } from "../../../../customHooks/queryHooks/procedureQueries";

export default function useSismaToolRoles() {
    const userId = useUserProfileStore(state => state.profile?.id)
    const isSuperAdmin = useUserProfileStore(state => state.isSuperAdmin)
    const { data: procedure } = useQueryProcedure()
    const { data: staff } = useQuerySismaToolStaff()
    const {hse, rspp, externals} = staff || {}
    if (isSuperAdmin || (procedure?.owner?.admins || []).find(id => id === userId)) {
        return {isHse: true, isRspp: true}
    }
    
    return {isHse: (hse || []).includes(userId), isRspp: (rspp || []).includes(userId), isExternal: (externals || []).includes(userId)}
}