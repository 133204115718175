import React from 'react'
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api'
import { italyCenter, google_maps_api_key, mapLibraries } from '../../constants'
import { colorsMapping } from '../../customTheme'
import ClusterIcon from '../../assets/customIcons/ClusterIcon_dark.png'
import LoadingSpinner from '../LoadingSpinner'
import {
    useProcedureSelectionOnMapStore,
    useMapStore,
    useProceduresPanelStore,
} from '../../stateManagement'
import { useTheme } from '@material-ui/core/styles'
import { faBridge, faBuilding } from '@fortawesome/free-solid-svg-icons'

import wtgSvg from '../../assets/customIcons/windmillIcon.svg'
const emptyArray = []
const mapOptions = {
    styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
        // {
        //     featureType: 'traffic',
        //     elementType: 'labels',
        //     stylers: [{ visibility: 'off' }],
        // },
        {
            featureType: 'road',
            stylers: [{ visibility: 'on' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
    ],
    streetViewControl: true,
    gestureHandling: 'cooperative',
    minZoom: 4,
}
const getProcedureIcon = (procedure) => ({
    bridge: faBridge.icon[4],
    building: faBuilding.icon[4],
    facility: faBuilding.icon[4],
    
}[procedure?.type] || faBuilding.icon[4])
function getMarkerColor(procedure) {
    const attentionClass =
        procedure?.methodologies[{
            bridge: 'mit_guidelines',
            building: 'resisto',
            facility: 'hexo'
        }[procedure?.type]]?.latest_result
        
    switch (attentionClass) {
        case 'ALTA':
        case '5':
        case 'allerta_2':
            return colorsMapping[5]
        case 'MEDIOALTA':
        case '4':
        case 'allerta_1':
            return colorsMapping[4]
        case 'MEDIA':
        case '3':
            return colorsMapping[3]
        case 'MEDIOBASSA':
        case '2':
        case 'allerta_0':
            return colorsMapping[2]
        case 'BASSA':
        case '1':
            return colorsMapping[1]
        case null:
        default:
            return colorsMapping[6]
    }
}
function getMarkerIcon(procedure) {
    if (procedure.type === 'windmill') {
        
        return {
                url: wtgSvg,
                fill: getMarkerColor(procedure),
                // strokeWidth: 10
        }
    }
    return {
        path: getProcedureIcon(procedure),
        fillColor: getMarkerColor(procedure),
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#ffffff',
        scale: 0.05,
    }
}
function arrayToCoords(someObj) {
    if (Array.isArray(someObj) && someObj.length === 2) {
        return { lat: someObj[0], lng: someObj[1] }
    } else if (someObj) {
        throw new Error('TypeError: expected [lat, lng] object but got ' + JSON.stringify(someObj))
    }
}
export default function Map() {
    const theme = useTheme()

    const listedProcedures =
        useProceduresPanelStore((state) => state?.listedProcedures) || emptyArray
    const [mapRef, setMapRef] = useMapStore((state) => [state.mapRef, state.setMapRef])
    const [proceduresListRef, selectedProcedureId, setSelectedProcedureId] =
        useProcedureSelectionOnMapStore((state) => [
            state.proceduresListRef,
            state.selectedProcedureId,
            state.setSelectedProcedureId,
        ])
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: google_maps_api_key,
        //do not remove, necessary for coordinates dialogs
        libraries: mapLibraries,
    })

    const [zoom, setZoom] = React.useState()

    const handleZoomChange = React.useCallback(() => {
        if (!mapRef?.getZoom) return
        // setZoom(mapRef.getZoom())
        setTimeout(() => setZoom(mapRef.getZoom()), 2000)
    }, [mapRef])

    const clusterKey = React.useMemo(
        () =>
            `zoom=${zoom};points=` +
            listedProcedures.map((procedure) => procedure.gps_position?.coordinates.join(';')),
        [zoom, listedProcedures]
    )

    const handleMarkerClick = React.useCallback(
        function (procedureId) {
            setSelectedProcedureId(procedureId)
            const index = listedProcedures.findIndex((p) => p.id === procedureId)
            if (index >= 0) {
                proceduresListRef.current.scrollToItem(index, 'center')
                mapRef.setMapTypeId('satellite')
                setZoom(22)
            }
        },
        [listedProcedures, proceduresListRef, mapRef, setSelectedProcedureId]
    )

    // function handleBoundsChanged() {
    //     // a ogni render il clusterer ricalcola più volte per ogni marker il cluster di appartenenza
    //     // con un db di 2k pratiche ca. lo sforzo computazionale del calcolo è enorme e blocca tutto
    //     // dopo infinite ricerche, l'unica soluzione trovata per adesso è:
    //     // posticipare il render dei cluster a quando la mappa ha presumibilmente finito di caricare
    //     // setTimeout(() => {
    //     //     setClusterKey(Math.random())
    //     // }, 500)
    //     setClusterKey(mapRef?.getZoom())
    // }

    // function changeMapTypeBasedOnZoom() {
    //     if (mapRef?.getZoom() <= 15) {
    //         mapRef?.setMapTypeId('roadmap')
    //     }
    // }
    React.useEffect(() => {
        if (!mapRef) return
        const bounds = new window.google.maps.LatLngBounds()
        listedProcedures.forEach((procedure) => {
            const coordObj = arrayToCoords(procedure?.gps_position?.coordinates)
            if (coordObj) {
                bounds.extend(coordObj)
            }
        })
        if (bounds.isEmpty()) {
            // bounds.extend(italyCenter)
            ;[
                {
                    lat: 47.01535664985804,
                    lng: 5.739116563143405,
                },
                {
                    lat: 36.03741719423527,
                    lng: 19.933452500643405,
                },
            ].forEach((coord) => bounds.extend(coord))
        }

        mapRef.fitBounds(bounds)
    }, [listedProcedures, mapRef])

    React.useEffect(() => {
        const procedure = listedProcedures.find((x) => x.id === selectedProcedureId)
        const validCoord = arrayToCoords(procedure?.gps_position?.coordinates)
        if (validCoord) {
            mapRef?.panTo(validCoord, { animated: true })
        }
    }, [listedProcedures, selectedProcedureId, mapRef])

    // memoized for performance optimization as suggested by the docs
    const styles = React.useMemo(
        () => ({
            mapContainerStyle: { flex: 1, borderRadius: '20px' },
            clusterer: [
                {
                    url: ClusterIcon,
                    width: 45,
                    height: 45,
                    textSize: 17,
                    textColor: theme.palette.secondary.main,
                },
            ],
        }),
        [theme]
    )

    const renderMarkers = React.useCallback(
        (clusterer) =>
            listedProcedures.map((procedure) => {
                const validCoord = arrayToCoords(procedure?.gps_position?.coordinates)
                return !validCoord ? null : (
                    <Marker
                        clusterer={clusterer}
                        key={procedure.id}
                        title={procedure.name}
                        icon={getMarkerIcon(procedure)}
                        onClick={() => {
                            handleMarkerClick(procedure.id)
                        }}
                        position={validCoord}
                        animation={procedure.id === selectedProcedureId ? 1 : 4}
                    />
                )
            }),
        [handleMarkerClick, listedProcedures, selectedProcedureId]
    )

    if (!isLoaded) return <LoadingSpinner />
    return (
        <GoogleMap
            onLoad={setMapRef} // onLoad is called with the googleMap object as an argument
            mapContainerStyle={styles.mapContainerStyle}
            mapTypeId={'roadmap'}
            center={italyCenter}
            onZoomChanged={handleZoomChange}
            // onBoundsChanged={handleBoundsChanged}
            zoom={6}
            tilt={45}
            options={mapOptions}
        >
            <MarkerClusterer maxZoom={18} styles={styles.clusterer} key={clusterKey}>
                {renderMarkers}
            </MarkerClusterer>
        </GoogleMap>
    )
}
