import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { debounce } from 'lodash'
import { CssBaseline, Grid } from '@material-ui/core'
import {
    makeStyles,
    ThemeProvider,
    createTheme,
    responsiveFontSizes,
} from '@material-ui/core/styles'

import { publicQueryClient, queryClient } from "./networking";
import { darkPalette, lightPalette, darkOverrides, lightOverrides } from './customTheme'
import { SeismicMonitoringStatus } from './screens/SeismicMonitoring/SeismicMonitoringStatus'
import { SeismicMonitoringTelemetry } from './screens/SeismicMonitoring/SeismicMonitoringTelemetry'
import { SeismicMonitoringConfiguration } from './screens/SeismicMonitoring/SeismicMonitoringConfiguration'
import { SeismicMonitoringEventDetail } from './screens/SeismicMonitoring/SeismicMonitoringEventDetail'
import { SeismicMonitoringEvents } from './screens/SeismicMonitoring/SeismicMonitoringEvents'
import { useThemeStore, useWindowWidthStore, useUserProfileStore } from './stateManagement'
import BackgroundTexture from './assets/BackgroundTexture.svg'
import TopBar from './components/TopBar'
import AppDrawer from './components/AppDrawer'
import ProcedurePage from './screens/ProcedurePage'
import CreateProcedurePage from './screens/CreateProcedurePage'
import UserProfilePage from './screens/UserProfilePage'
import CompaniesPage from './screens/CompaniesPage'
import CompanyDetailsPage from './screens/CompanyDetailsPage'
import LoginPage from './screens/LoginPage'
import LGP_LA0_censimento from './screens/LGP/LA0/censimento'
import LGP_LA0_documentazione from './screens/LGP/LA0/documentazione'
import LGP_LA1_scheda_descrittiva from './screens/LGP/LA1/scheda_descrittiva'
import LGP_LA1_fenomeni from './screens/LGP/LA1/fenomeni'
import LGP_LA1_fenomeni_scheda from './screens/LGP/LA1/fenomeni_scheda'
import LGP_LA1_fenomeni_frane from './screens/LGP/LA1/fenomeni_frane'
import LGP_LA1_fenomeni_idraulico from './screens/LGP/LA1/fenomeni_idraulico'
import LGP_LA1_difettosita from './screens/LGP/LA1/difettosita'
import LGP_LA1_difetto_overview from './screens/LGP/LA1/difetto_overview'
import LGP_LA1_scheda_difetto from './screens/LGP/LA1/scheda_difetto'
import LGP_LA1_scheda_elemento_accessorio from './screens/LGP/LA1/scheda_elemento_accessorio'
import LGP_LA2 from './screens/LGP/LA2'
import LGP_LA3 from './screens/LGP/LA3/index'
import LGP_LA3_dati from './screens/LGP/LA3/dati'
import LGP_LA4 from './screens/LGP/LA4'
import Resisto_PGAD from './screens/resisto/PGAD'
import Resisto_sopralluogo from './screens/resisto/sopralluogo'
import Bms from './screens/BMS/BMS'
import Homepage from './screens/Homepage'
import ProcedureAccessPage from './screens/ProcedureAccessPage'
import GlobalSnackBar from './components/GlobalSnackBar'
import ProcedureRedirect from './components/ProcedureRedirect'
import Resisto_livelli from './screens/resisto/livelli'
import Resisto_livello_details from './screens/resisto/livello_details'
import UsersManagementPage from './screens/UsersManagementPage'
import EducationAreaPage from './screens/EducationAreaPage'
import ChangelogPage from './screens/ChangelogPage'
import GdprPage from './screens/GdprPage'
import CookiesPage from './screens/CookiesPage'
import HelpPage from './screens/HelpPage'
import EnvBar from './components/EnvBar'
import PrivateRoute from './PrivateRoute'
import InspectionCalendar from './screens/calendar/InspectionCalendar'
import LandingPage from './screens/LandingPage/LandingPage'
import { Panoramica } from './screens/resisto/Panoramica'
import { Scheda_gndt } from './screens/resisto/Scheda_gndt'
import { Stato_edificio } from './screens/resisto/Stato_edificio'
import { Vulnerabilità_locali } from './screens/resisto/Vulnerabilità_locali'
import AnalyticsDashboard from './screens/dashboard/AnalyticsDashboard'
import AdminIndex from './screens/admin/AdminIndex'
import AnalysisCatalog from './screens/admin/analysis/AnalysisCatalog'
import { getMethodologyStoredFiltersProvider } from './customHooks/storedFiltersContext'
import FieldPc from './screens/admin/field_pc/FieldPc'
import PLC from './screens/admin/plc/PLC'
import DynamicsProcedurePage from './screens/Dynamics/DynamicsProcedurePage'
import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    Filler,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import 'chartjs-plugin-zoom'
import zoomPlugin from 'chartjs-plugin-zoom'
import ProcedureMethodologyRouter from "./screens/ProcedureMethodologyRouter";
import SismaToolEventUnprotectedView from "./screens/methodologies/sisma_tool/views/SismaToolEventUnprotectedView";
import SismaToolProcedureUnprotectedView from "./screens/methodologies/sisma_tool/views/SismaToolProcedureUnprotectedView";

ChartJS.register(
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    Filler,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
)

const FiltersProvider = getMethodologyStoredFiltersProvider()
const useStyles = makeStyles(() => ({
    appContainer: {
        height: '100vh',
    },
    pageContainer: {
        flex: 1,
        backgroundImage: `url('${BackgroundTexture}')`,
    },
}))

const storedThemeMode = localStorage.getItem('themeMode')
useThemeStore.setState({ themeMode: storedThemeMode || 'light' })
const defaultTheme = responsiveFontSizes(
    createTheme(
        {
            palette: {
                type: storedThemeMode || 'light',
                ...(storedThemeMode === 'dark' ? darkPalette : lightPalette),
            },
            overrides: storedThemeMode === 'dark' ? darkOverrides : lightOverrides,
        },
        { index: 1 }
    )
)

function App() {
    const classes = useStyles()
    const setWindowWidth = useWindowWidthStore((state) => state.setWidth)
    const themeMode = useThemeStore((state) => state.themeMode)
    const isUsersManager = useUserProfileStore((state) => state.isUsersManager)
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const [theme, setTheme] = React.useState(defaultTheme)
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const currentUrl = window.location.pathname

    React.useEffect(() => {
        const customTheme = responsiveFontSizes(
            createTheme(
                {
                    palette: {
                        type: themeMode,
                        ...(themeMode === 'dark' ? darkPalette : lightPalette),
                    },
                    overrides: themeMode === 'dark' ? darkOverrides : lightOverrides,
                },
                { index: 1 }
            )
        )
        setTheme(customTheme)
    }, [themeMode])

    React.useEffect(() => {
        // need it for cutting too long input labels
        function handleResize(e) {
            setWindowWidth(e.target.outerWidth)
        }
        const debouncedHandleResize = debounce(handleResize, 700)
        window.addEventListener('resize', debouncedHandleResize)
        return () => window.removeEventListener('resize', debouncedHandleResize)
    }, [setWindowWidth])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <QueryClientProvider client={queryClient}>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <Router>
                    <EnvBar />
                    {currentUrl === '/'
                    ? <LandingPage />
                    : currentUrl.startsWith('/pub') ? (
                        <Switch>
                            <Route exact path={"/pub/procedures/:procedureId/hexo/events/:eventId"} component={SismaToolEventUnprotectedView} />
                            <Route exact path={"/pub/procedures/:procedureId/hexo"} component={SismaToolProcedureUnprotectedView} />
                        
                        </Switch>
                        )
                     : (
                        <FiltersProvider>
                            <AppDrawer open={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                            <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                className={classes.appContainer}
                            >
                                <TopBar onHamburgerClick={() => setIsDrawerOpen(true)} />
                                <Grid
                                    item
                                    className={classes.pageContainer}
                                    style={{
                                        backgroundColor: theme.palette.background.page,
                                    }}
                                >
                                    <Switch>
                                        <Route path="/login" component={LoginPage} />

                                        <PrivateRoute
                                            exact
                                            path={'/admin'}
                                            isAllowed={isSuperAdmin}
                                            component={AdminIndex}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={'/admin/analysis'}
                                            isAllowed={isSuperAdmin}
                                            component={AnalysisCatalog}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={'/admin/field_pc'}
                                            isAllowed={isSuperAdmin}
                                            component={FieldPc}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={'/admin/plc'}
                                            isAllowed={isSuperAdmin}
                                            component={PLC}
                                        />
                                        {/*<Route*/}
                                        {/*    exact*/}
                                        {/*    path="/admin/analysis/:analysisId"*/}
                                        {/*    component={AnalysisCatalogVersions}*/}
                                        {/*/>*/}
                                        <Route
                                            exact
                                            path="/home/:companyId?"
                                            component={Homepage}
                                        />
                                        <Route
                                            exact
                                            path="/dashboard/lgp/:companyId?"
                                            component={AnalyticsDashboard}
                                        />
                                        

                                        <Route exact path="/companies" component={CompaniesPage} />
                                        <Route
                                            path="/companies/:companyId"
                                            component={CompanyDetailsPage}
                                        />
                                        <Route
                                            path="/procedures/init"
                                            component={CreateProcedurePage}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId"
                                            component={ProcedurePage}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/access"
                                            component={ProcedureAccessPage}
                                        />

                                        {/* DYNAMICS */}
                                        <Route
                                            path={'/procedures/:procedureId/dynamics'}
                                            component={DynamicsProcedurePage}
                                        />

                                        {/* STATICS */}
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/overview/index"
                                            component={React.Fragment}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/overview/analysis_list"
                                            component={React.Fragment}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/overview/analysis_result"
                                            component={React.Fragment}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/configure/infrastructure"
                                            component={React.Fragment}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/configure/analysis"
                                            component={React.Fragment}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/statics/configure/plc"
                                            component={React.Fragment}
                                        />

                                        {/* LGP */}
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/lgp"
                                            component={LGP_LA2}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-0/censimento"
                                            component={LGP_LA0_censimento}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-0/documentazione"
                                            component={LGP_LA0_documentazione}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-0"
                                            component={ProcedureRedirect}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/scheda-descrittiva"
                                            component={LGP_LA1_scheda_descrittiva}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/lgp/livello-1/frane-e-fenomeni-idraulici"
                                            component={LGP_LA1_fenomeni}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/frane-e-fenomeni-idraulici/scheda"
                                            component={LGP_LA1_fenomeni_scheda}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/frane-e-fenomeni-idraulici/rischio-frane"
                                            component={LGP_LA1_fenomeni_frane}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/frane-e-fenomeni-idraulici/rischio-idraulico"
                                            component={LGP_LA1_fenomeni_idraulico}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/lgp/livello-1/difettosita"
                                            component={LGP_LA1_difettosita}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/difettosita/elementi-accessori"
                                            component={LGP_LA1_scheda_elemento_accessorio}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/lgp/livello-1/difettosita/:elementoStrutturale"
                                            component={LGP_LA1_difetto_overview}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1/difettosita/:elementoStrutturale/:index"
                                            component={LGP_LA1_scheda_difetto}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-1"
                                            component={ProcedureRedirect}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-2"
                                            component={LGP_LA2}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-3"
                                            exact
                                            component={LGP_LA3}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-3/dati"
                                            exact
                                            component={LGP_LA3_dati}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/livello-4"
                                            component={LGP_LA4}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp/bms"
                                            component={Bms}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/lgp"
                                            component={ProcedureRedirect}
                                        />

                                        {/* RESISTO */}
                                        <Route
                                            path="/procedures/:procedureId/resisto/panoramica"
                                            component={Panoramica}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/pgad"
                                            component={Resisto_PGAD}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/sopralluogo"
                                            component={Resisto_sopralluogo}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/resisto/livelli"
                                            component={Resisto_livelli}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/scheda-gndt-II-liv"
                                            component={Scheda_gndt}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/stato-edificio"
                                            component={Stato_edificio}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/vulnerabilita-locali"
                                            component={Vulnerabilità_locali}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/resisto/livelli"
                                            component={Resisto_livelli}
                                        />
                                        <Route
                                            path="/procedures/:procedureId/resisto/livelli/:levelId"
                                            component={Resisto_livello_details}
                                        />

                                        {/* SEISMIC_MONITORING */}
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/seismic_monitoring/overview"
                                            component={SeismicMonitoringStatus}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/seismic_monitoring/telemetry"
                                            component={SeismicMonitoringTelemetry}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/seismic_monitoring/events"
                                            component={SeismicMonitoringEvents}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/seismic_monitoring/events/:eventId"
                                            component={SeismicMonitoringEventDetail}
                                        />
                                        <Route
                                            exact
                                            path="/procedures/:procedureId/seismic_monitoring/configuration"
                                            component={SeismicMonitoringConfiguration}
                                        />
                                        
                                        
                                        {/* OTHER METHODOLOGIES */}
                                        <Route
                                            path={'/procedures/:procedureId/:methodology'}
                                            component={ProcedureMethodologyRouter}
                                        />

                                        {/* USER */}
                                        <Route path="/user/profile" component={UserProfilePage} />
                                        <PrivateRoute
                                            path={'/users'}
                                            isAllowed={isSuperAdmin || isUsersManager}
                                            component={UsersManagementPage}
                                        />
                                        <Route path="/calendar" component={InspectionCalendar} />
                                        <Route path="/education" component={EducationAreaPage} />
                                        <Route path="/help" component={HelpPage} />
                                        <Route path="/changelog" component={ChangelogPage} />
                                        <Route path="/privacy-policy" component={GdprPage} />
                                        <Route path="/cookie-policy" component={CookiesPage} />
                                    </Switch>
                                    <GlobalSnackBar />
                                </Grid>
                            </Grid>
                        </FiltersProvider>
                    )}
                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App
