import { MethodologyButton, MethodologySectionPaper, MethodologySectionTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import UserCard from "../../../../components/UserCard";
import { useMutateSismaToolStaff, useQuerySismaToolStaff } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import AddIcon from "@material-ui/icons/Add";
import useDialogControls from "../../../../customHooks/useDialogControls";
import AddStaffMemberDialog from "../dialogs/AddStaffMemberDialog";


function Staff() {
    const { t } = useTranslation()
    const {data: staff} = useQuerySismaToolStaff()
    const hseUsers = staff?.hse || []
    const rsppUsers = staff?.rspp || []
    const externalUsers = staff?.externals || []
    const { mutateAsync: toggleHseMember } = useMutateSismaToolStaff({action: 'toggle_hse'})
    const { mutateAsync: toggleRsppMember } = useMutateSismaToolStaff({action: 'toggle_rspp'})
    const { mutateAsync: toggleExternalMember } = useMutateSismaToolStaff({action: 'toggle_externals'})
    
    const addStaffMemberDialogControls = useDialogControls()
    
    return (
        <>

            <MethodologySectionPaper>
                <Grid item container display={'flex'} direction={'column'} spacing={2}>
                    
                    <Grid item>
                        <MethodologySectionTitle>
                            {t('RSPP')}
                        </MethodologySectionTitle>
                    </Grid>
                    <Grid item container spacing={3} display={'flex'} justifyContent={'center'}>
                        {rsppUsers.map((user) => <UserCard user={user} menuItems={[{onClick: () => toggleRsppMember({userId: user?.id}), title: 'remove' }]} />)}
                    </Grid>
                    <Grid item container display={'flex'} justifyContent={'flex-end'}>
                        <Grid item>
                            <MethodologyButton onClick={() => addStaffMemberDialogControls.open({role: 'rspp'})}>
                                <AddIcon />
                                {t('add_rspp_user')}
                            </MethodologyButton>
                        
                        </Grid>
                    </Grid>
                
                </Grid>
            </MethodologySectionPaper>
            <MethodologySectionPaper>
                <Grid item container display={'flex'} direction={'column'} spacing={2}>
                    
                    <Grid item>
                        <MethodologySectionTitle>
                            {t('HSE')}
                        </MethodologySectionTitle>
                    </Grid>
                    <Grid item container spacing={3} display={'flex'} justifyContent={'center'}>
                        {hseUsers.map((user) => <UserCard user={user} menuItems={[{onClick: () => toggleHseMember({userId: user?.id}), title: 'remove' }]} />)}
                    </Grid>
                    <Grid item container display={'flex'} justifyContent={'flex-end'}>
                        <Grid item>
                            <MethodologyButton onClick={() => addStaffMemberDialogControls.open({role: 'hse'})}>
                                <AddIcon />
                                {t('add_hse_user')}
                            </MethodologyButton>
                        
                        </Grid>
                    </Grid>
                
                </Grid>
            </MethodologySectionPaper>
            <MethodologySectionPaper>
                <Grid item container display={'flex'} direction={'column'} spacing={2}>
                    
                    <Grid item>
                        <MethodologySectionTitle>
                            {t('HSE_externals')}
                        </MethodologySectionTitle>
                    </Grid>
                    <Grid item container spacing={3} display={'flex'} justifyContent={'center'}>
                        {externalUsers.map((user) => <UserCard user={user} menuItems={[{onClick: () => toggleExternalMember({userId: user?.id}), title: 'remove' }]} />)}
                    </Grid>
                    <Grid item container display={'flex'} justifyContent={'flex-end'}>
                        <Grid item>
                            <MethodologyButton onClick={() => addStaffMemberDialogControls.open({role: 'externals'})}>
                                <AddIcon />
                                {t('add_hse_external_user')}
                            </MethodologyButton>
                        
                        </Grid>
                    </Grid>
                
                </Grid>
            </MethodologySectionPaper>
            <AddStaffMemberDialog controls={addStaffMemberDialogControls} />
        </>
    )
}

export default Staff