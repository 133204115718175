import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import React from "react";
import Events from "./views/Events";
import Buildings from "./views/Buildings";
import Staff from "./views/Staff";
import Configuration from "./views/Configuration";
import Telemetry from "./views/Telemetry";
import SismaToolEvent from "./views/SismaToolEvent";
import SismaToolEventUnprotectedView from "./views/SismaToolEventUnprotectedView";

const defaultView = 'buildings'

function SismaToolRouter({match}) {
    const {pathname, search} = useLocation()
    const { replace } = useHistory()
    
    if (pathname === match.url) {
        console.log('replacing')
        replace(`${pathname}/${defaultView}${search}`)
    }
    return (
            <Switch>
                
                <Route
                    exact
                    path={`${match.path}/events`}
                    component={Events}
                />
                <Route
                    exact
                    path={`${match.path}/events/:eventId`}
                    component={SismaToolEventUnprotectedView}
                />
                <Route
                    path={`${match.path}/buildings`}
                    component={Buildings}
                />
                <Route
                    path={`${match.path}/staff`}
                    component={Staff}
                />
                <Route
                    path={`${match.path}/configuration`}
                    component={Configuration}
                />
                <Route
                    path={`${match.path}/telemetry`}
                    component={Telemetry}
                />

            </Switch>
    )
}

export default SismaToolRouter;
