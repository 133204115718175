import _ from 'lodash'
import { useMemo } from 'react'
import {
    createSelect,
    textType,
    createFieldArray,
    booleanType,
    numberType,
    numberUnitType,
} from '../../FormConfigs/utils'
import { useQueryProcedurePlcConfigurations } from '../queryHooks/methodologyQueries/dynamicsQueries'

const prepare = (data) => {
    const preparedData = _.cloneDeep(data)
    preparedData.sensor_groups = preparedData.sensor_groups.map((group) => ({
        ...group,
        sensors: group.sensors.map((sensor) => sensor.id),
        thresholds_milli_g: group.thresholds_milli_g.map((threshold) => threshold.value),
    }))
    console.debug('preparedData', preparedData)
    return preparedData
}

export function useSeismicMonitoringConfigurationFields(methods) {
    const { data: procedurePlcConfigurations } = useQueryProcedurePlcConfigurations()
    const procedure_plc_configuration_id = methods.watch('procedure_plc_configuration_id')

    const formFields = useMemo(() => {
        const procedurePlcConfigurationsOptions = (procedurePlcConfigurations || []).map((c) => ({
            value: c.id,
            label: c.id + ' - ' + c.created_at,
            sensor_count: c.plc_configuration.sensor_count,
        }))

        return {
            procedure_plc_configuration_id: {
                ...createSelect(procedurePlcConfigurationsOptions),
            },
            sensor_groups: createFieldArray({
                name: textType,
                sensors: createFieldArray({
                    id: {
                        ...numberType,
                        min: 1,
                        max: procedurePlcConfigurationsOptions.find(
                            (o) => o.value === procedure_plc_configuration_id
                        )?.sensor_count,
                        step: 1,
                    },
                }),
                thresholds_milli_g: createFieldArray({
                    ...numberUnitType('mg'),
                    label: 'threshold',
                }),
                is_ground: booleanType,
            }),
        }
    }, [procedurePlcConfigurations, procedure_plc_configuration_id])

    return { formFields, prepare }
}
