import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { MethodologyButton } from "../../components";
import { useTranslation } from "react-i18next";
import { useAcknowledgeSismaToolAlerts } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";



export default function AcknowledgeAllDialog({controls}) {
    const { t } = useTranslation()
    const { isOpen, close, state } = controls
    const procedureData = state?.procedureData
    
    const {mutateAsync: acknowledgeAlerts} = useAcknowledgeSismaToolAlerts()
    return (
        <Dialog open={isOpen && !!procedureData} onClose={close} fullWidth={true} maxWidth="md">
            <DialogTitle>
                {t('acknowledge')}
            </DialogTitle>
            <DialogContent>
                Stai confermando di aver preso visione di tutte le azioni previste per il fabbricato {procedureData?.name}. Desideri continuare?
            </DialogContent>
            <DialogActions>
                <MethodologyButton variant={'contained'} color={'primary'} onClick={close}>
                    Annulla
                </MethodologyButton>
                <MethodologyButton onClick={() => acknowledgeAlerts(procedureData?.id).then(() => close())}>
                    Conferma
                </MethodologyButton>
            </DialogActions>
        </Dialog>
    )
}


