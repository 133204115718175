import { useTranslation } from "react-i18next";
import React from "react";
import { Avatar, Card, CardHeader, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import { backend_url } from "../constants";
import { getInitials } from "../utils";
import { useSnackBarStore } from "../stateManagement";


const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.text.secondary,
    },
    role: {
        fontSize: '0.7rem',
        color: theme.palette.text.secondary,
    },
    action: {
        marginLeft: 8,
    },
    cardContainer: {
        position: 'relative',
    },
    secondaryIcon: {
        position: 'absolute',
        top: 5,
        left: 0,
        height: '15px',
        color: theme.palette.secondary.main,
    },
    copyIcon: {
        height: '10px',
        verticalAlign: 'baseline',
        marginLeft: '-5px',
    },
    mail: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
}))

function UserAvatar({user}) {
    const classes = useStyles()
    return (
        <Avatar
            src={
                user?.image?.depot_url
                    ? String(new URL(user?.image?.depot_url, backend_url))
                    : null
            }
            alt={`${user.first_name} ${user.last_name}`}
            className={classes.avatar}
        >
            {getInitials(user.first_name, user.last_name)}
        </Avatar>
    )
}

function useCopyToClipboard({success_message, error_message}) {
    const {t} = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const copyToClipboard = React.useCallback((dataToCopy) => {
        navigator.clipboard.writeText(dataToCopy).then(
            () => {
                showSnackBar({
                    message: success_message || t('copied_text'),
                    severity: 'success',
                })
            },
            () => {
                showSnackBar({
                    message: error_message || t('error_clipboard'),
                    severity: 'error',
                })
            }
        )
    }, [showSnackBar, success_message, error_message, t])
    
    return { copyToClipboard }
}


export default function UserCard({
    user,
    avatarAdornment,
    menuItems
}) {
    const {t} = useTranslation()
    const classes = useStyles()
    const {copyToClipboard} = useCopyToClipboard({success_message: t('copied_email')})
    const [anchorElement, setAnchorElement] = React.useState(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    
    const openMenu = React.useCallback((e) => {
        e.stopPropagation()
        setAnchorElement(e.target)
        setIsMenuOpen(true)
    }, [setIsMenuOpen, setAnchorElement])
    
    const closeMenu = React.useCallback((e) => {
        e?.stopPropagation()
        setAnchorElement(null)
        setIsMenuOpen(false)
    }, [setAnchorElement, setIsMenuOpen])
    
    
    return <Grid item className={classes.cardContainer}>
        <Card>
            <CardHeader
                classes={{
                    root: classes.card,
                    action: classes.action,
                }}
                avatar={<>{avatarAdornment}<UserAvatar user={user}/></>}
                title={`${user.first_name} ${user.last_name}`}
                subheader={
                    <>
                        <div
                            className={classes.mail}
                            onClick={() => copyToClipboard(user.email)}
                        >
                            {user.email} <FileCopyIcon className={classes.copyIcon} />
                        </div>
                    </>
                }
                subheaderTypographyProps={{
                    variant: 'caption',
                    className: classes.role,
                }}
                action={!!menuItems && (
                    <>
                        <IconButton
                            color="secondary"
                            size="small"
                            onClick={(e) => openMenu(e)}
                            disabled={!menuItems?.length}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            open={isMenuOpen}
                            onClose={closeMenu}
                            anchorEl={anchorElement}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {menuItems.map(menuItem => (
                                <MenuItem
                                    className={classes.option}
                                    onClick={menuItem.onClick}
                                    disabled={menuItem?.disabled}
                                >
                                    {menuItem.title}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            
            />
        </Card>
    </Grid>
    
}
