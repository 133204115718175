import { useQuery } from 'react-query'
import { availableCompaniesQueries, companiesQueries } from '../../networking'
import useCompanyId from "../useCompanyId";
import { getFiltersParam } from "../../utils";
import axios from "axios";
import { useStoredFilters } from "../storedFiltersContext";

export function useQueryCompanyAnalytics() {
    const { companyId } = useCompanyId()
    return useQuery(
        [availableCompaniesQueries.getCompanyAnalytics.name, companyId],
        () => availableCompaniesQueries.getCompanyAnalytics.fn(companyId),
        {
            enabled: !!companyId,
        }
    )
}

export function useQueryCompany({companyId} = {}) {
    const { companyId: _companyId } = useCompanyId()
    companyId = companyId || _companyId
    return useQuery(
        [companiesQueries.getCompany.name, companyId],
        () => companiesQueries.getCompany.fn(companyId),
        {enabled: !!companyId}
    )
}

export function useQueryExtraFieldValues({companyId} = {}) {
    const { companyId: _companyId } = useCompanyId()
    companyId = companyId || _companyId
    return useQuery(
        [companiesQueries.getExtraFieldValues.name, companyId],
        () => companiesQueries.getExtraFieldValues.fn(companyId),
        {
            enabled: !!companyId && companyId !== 'tutti_gli_studi',
            onSuccess: (data) => {
                // console.log('fetched extra field values', data)
                return data
            },
        }
    )
}

export function useQueryCompanyMethodologyAnalytics() {
    const {companyId, methodology} = useCompanyId()
    const beMethodology = {"lgp": "mit_guidelines"}[methodology]
    const [filters] = useStoredFilters(state => [state.filters])
    const {type, ...rules} = filters?.rules || {}
    const params = getFiltersParam({rules})
    return useQuery(
        [availableCompaniesQueries.getMethodologyAnalytics.name, companyId, methodology, params],
        async () => {
            const res = await axios.get(`/companies/${companyId}/analytics/${beMethodology}?${params}`)
            return res.data
        },
        {
            enabled: !!beMethodology && !!companyId
        }
    )
}

export function useQueryCompanyMethodologyExports({companyId, beMethodology} = {}) {
    const { companyId: _companyId, beMethodology: _beMethodology } = useCompanyId()
    companyId = companyId || _companyId
    beMethodology = beMethodology || _beMethodology
    return useQuery(
        ['getCompanyMethodologyExports', companyId, beMethodology],
        async () => {
            const res = await axios.get(`/companies/${companyId}/exports/${beMethodology}`)
            return res.data
        },
        {
            enabled: !!beMethodology && !!companyId,
            refetchInterval: 5000
        }
    )
}