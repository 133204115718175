import React from 'react'

import { useForm } from "react-hook-form";
import { numberType } from "../../../../FormConfigs/utils";
import dayjs from "dayjs";

const fields = Object.freeze({
    pga_max_microg: { ...numberType, required: true, label: 'pga_max_centig' },
    event_began_at_timestamp: { _type: 'dateTime', required: true },
    event_ended_at_timestamp: {
        _type: 'dateTime',
    },
})

function prepare(data) {
    return {
        ...data,
        pga_max_microg: (data?.pga_max_microg ?? 0) * 10_000,
        event_began_at_timestamp: dayjs(data.event_ended_at_timestamp).valueOf() / 1000,
        event_ended_at_timestamp: data?.event_ended_at_timestamp ? dayjs(data?.event_ended_at_timestamp).valueOf() / 1000 : undefined
    }
}


export default function useCreateSismaToolEventForm() {
    const methods = useForm({
        mode: 'onBlur',
    })
    
    return {methods, fields, formId: `create_sisma_tool_event`, prepare}
}