import { Button } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/LoadingSpinner'
import SeismicMonitoringConfigurationDetails from '../../components/SeismicMonitoring/SeismicMonitoringConfigurationDetails'
import { useQuerySeismicMonitoringConfiguration } from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import { useQueryProcedure } from '../../customHooks/queryHooks/procedureQueries'
import MethodologyPage from '../MethodologyPage'
import { SeismicMonitoringConfigurationModal } from './SeismicMonitoringConfigurationModal'

export const SeismicMonitoringConfiguration = () => {
    const { data: procedure, isLoading } = useQueryProcedure()
    const {
        data: seismicMonitoringConfiguration,
        isLoading: isSeismicMonitoringConfigurationLoading,
    } = useQuerySeismicMonitoringConfiguration(
        _.get(procedure, [
            'methodologies',
            'seismic_monitoring',
            'seismic_monitoring_configuration_id',
        ])
    )
    const { t } = useTranslation()

    const [isConfigModalOpen, setIsConfigModalOpen] = React.useState(false)

    if (isLoading || isSeismicMonitoringConfigurationLoading) {
        return <LoadingSpinner />
    }
    return (
        <MethodologyPage methodology="seismic_monitoring">
            <h2>Seismic Monitoring</h2>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsConfigModalOpen(true)}
                style={{ marginBottom: 16 }}
            >
                {seismicMonitoringConfiguration
                    ? t('update_seismic_monitoring_configuration')
                    : t('create_seismic_monitoring_configuration')}
            </Button>

            {seismicMonitoringConfiguration && (
                <SeismicMonitoringConfigurationDetails
                    configuration={seismicMonitoringConfiguration}
                />
            )}
            <SeismicMonitoringConfigurationModal
                open={isConfigModalOpen}
                onClose={() => setIsConfigModalOpen(false)}
            />
        </MethodologyPage>
    )
}
