import { useQuery } from "react-query";
import { usersQueries } from "../../networking";
import { useSnackBarStore } from "../../stateManagement";

export function useQueryUserDetails({email}) {
    const {show} = useSnackBarStore()
    return useQuery(
        [usersQueries.searchUser.name, email],
        () => usersQueries.searchUser.fn(email),
        {
            enabled: !!email,
            retry: false,
            onError: () => show({message: 'unable to find user details', severity: 'error'})
        }
    )
}