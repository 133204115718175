import React from 'react'
import {
    AlertBadge, alertChar, alertLevelScore, eventSequentialId,
    MethodologyAccordionSummary, MethodologyButton,
    MethodologySectionPaper,
    MethodologySectionTitle, MethodologyTableCell,
    MethodologyTooltip
} from "../../components";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionActions, AccordionDetails,
    Checkbox,
    Divider, FormControlLabel,
    Grid,
    IconButton, Table,
    TableBody, TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { useQuerySismaToolAlerts } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { capitalizeFirstLetter } from "../../../../utils";
import { useHistory } from "react-router-dom";
import { OpenInNew } from "@material-ui/icons";
import dayjs from "dayjs";
import useDialogControls from "../../../../customHooks/useDialogControls";
import AcknowledgeAllDialog from "../dialogs/AcknowledgeAllDialog";
import AcknowledgeDialog from "../dialogs/AcknowledgeDialog";
import { AppChip } from "../../../../components/QuickFilterChip";
import useProcedureId from "../../../../customHooks/useProcedureId";
import useSismaToolRoles from "../hooks/useSismaToolRoles";







function Buildings() {
    const { t } = useTranslation()
    
    const { data: alertsByProcedure } = useQuerySismaToolAlerts()
    
    
    const procedures = React.useMemo(() => Object.entries(alertsByProcedure || {}).map(
        ([subProcedureId, { name, alerts }]) => {
            const alertLevel = Math.max(1, ...alerts.filter(alert => !alert.acknowledged_at).map(alert => alertLevelScore(alert)))
            return {
                subProcedureId,
                alertLevel,
                name,
                alerts: alerts.sort((a, b) => b.event.event_began_at_timestamp - a.event.event_began_at_timestamp),
            }
        }
    ), [alertsByProcedure])
    
    return <>
        <MethodologySectionPaper>
            <MethodologySectionTitle>
                {capitalizeFirstLetter(t('buildings_state'))}
            </MethodologySectionTitle>
            
            {procedures.map(({name, alerts, subProcedureId, alertLevel}) => (
                <BuildingStatusAccordionItem
                    key={subProcedureId}
                    name={name}
                    alerts={alerts}
                    subProcedureId={subProcedureId}
                    alertLevel={alertLevel}
                />
            ))}
            
        </MethodologySectionPaper>
    </>
}

export default Buildings


function BuildingStatusAccordionItem({name, alerts, subProcedureId, alertLevel}) {
    const { t } = useTranslation()
    const { push } = useHistory()
    const {procedureId} = useProcedureId()
    
    const [hideAcknowledged, setHideAcknowledged] = React.useState(true)
    const [hideNoAction, setHideNoAction] = React.useState(false)
    
    const { open: openAcknowledgeAllDialog, ...acknowledgeAllDialogControls } = useDialogControls()
    const { open: openAcknowledgeDialog, ...acknowledgeDialogControls } = useDialogControls()
    
    const pendingActionsCount = (alerts || []).filter(alert => !alert?.acknowledged_at).length
    const {isRspp, isHse} = useSismaToolRoles()
    
    // const [tableColumns, tableRows] = React.useMemo(() => [
    //     [
    //         {
    //             field: 'date',
    //             headerName: capitalizeFirstLetter(t('date_and_time')),
    //             // width: '18%',
    //         },
    //         {
    //             field: 'alertLevel',
    //             headerName: t('alert_level'),
    //             // width: '5%',
    //             renderCell: null
    //         },
    //         {
    //             field: 'eventLink',
    //             headerName: t('event_details'),
    //             // width: '5%',
    //             renderCell: null
    //         },
    //         {
    //             field: 'prescribedAction',
    //             headerName: capitalizeFirstLetter(t('prescribed_action')),
    //             // width: '10%',
    //         },
    //         {
    //             field: 'accessRestriction',
    //             headerName: capitalizeFirstLetter(t('access_restriction')),
    //             // width: '10%',
    //         },
    //         {
    //             field: 'acknowledgedBy',
    //             headerName: t('acknowledged_by'),
    //             // width: '20%',
    //         },
    //         {
    //             field: 'acknowledgedAt',
    //             headerName: t('acknowledged_at'),
    //             // width: '15%',
    //         },
    //         {
    //             field: 'onClickAcknowledge',
    //             headerName: '',
    //             renderCell: ({row}) => <MethodologyButton onClick={row.onClickAcknowledge} disabled={!row.onClickAcknowledge}>{t('acknowledge')}</MethodologyButton>
    //         }
    //     ],
    //     alerts.filter(alert => (!hideAcknowledged) || (!alert?.acknowledged_at))
    //     .filter(alert => (!hideNoAction) || (!alert?.prescribed_action === 'nessuna'))
    //     .map(alert => ({
    //         id: alert.id,
    //         date: dayjs(alert.event.event_began_at_timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') + (alert?.event?.sequential_id ? ` (${alert.event.sequential_id})` : ''),
    //         alertLevel: alert?.alertLevel,
    //         eventLink: `/procedures/${subProcedureId}/methodologies/hexo/events/${alert?.event?.id}`,
    //         prescribedAction: t(alert?.prescribed_action),
    //         accessRestriction: t(alert?.access_restriction),
    //         acknowledgedBy: alert?.acknowledged_by ? `${alert.acknowledged_by.first_name || ''} ${alert.acknowledged_by.last_name || ''}` : '',
    //         acknowledgedAt: alert?.acknowledged_at ? dayjs(alert.acknowledged_at).format('YYYY-MM-DD HH:mm:ss') : '',
    //         onClickAcknowledge: alert?.acknowledged_at ? undefined : () => openAcknowledgeDialog({procedureData: {name, id: subProcedureId}, alertData: alert})
    //     }))
    // ], [alerts, hideAcknowledged, hideNoAction, openAcknowledgeDialog, name, subProcedureId, t])
    
    return (
        <>
            <Accordion rounded={true} TransitionProps={{ unmountOnExit: true }}>
                <MethodologyAccordionSummary rightAdornment={(
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {!!pendingActionsCount && <span style={{marginRight: 8}}><AppChip label={`${alertChar(alertLevel)}\t ${t('pending_actions')}: ${pendingActionsCount}`}></AppChip></span>}
                        {/*<AlertBadge alertLevel={alertLevel} />*/}
                    </div>
                )}>
                    {name}
                    <IconButton>
                        <OpenInNew
                            fontSize={'small'}
                            onClick={() => push(`/procedures/${subProcedureId}`)}
                        />
                    </IconButton>
                </MethodologyAccordionSummary>
                <Divider />
                <AccordionActions>
                    <Grid container alignItems={'center'}
                          spacing={2}
                        style={{
                            paddingLeft: 20, paddingRight: 20,
                            
                        }}
                          justifyContent={'space-between'}
                    >
                        <Grid item container alignItems={'center'} spacing={1} xs>
                            <Grid item>
                                <Typography>{`${t('aggregate_alert_level')}: `}
                                <AlertBadge alertLevel={alertLevel} />
                                        {alertLevel - 1}
                                </Typography>
                                
                                
                            </Grid>
                            <Grid item>
                            
                            
                      
                                <FormControlLabel
                                    control={<Checkbox checked={hideAcknowledged} onChange={() => setHideAcknowledged((value) => !value)}/>}
                                    label={t('hide_acknowledged')}
                                />
                            </Grid>
                            <Grid item>
                      
                                <FormControlLabel
                                    control={<Checkbox checked={hideNoAction}  onChange={() => setHideNoAction((value) => !value)}/>}
                                    label={t('hide_no_action')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <MethodologyButton
                                onClick={() => openAcknowledgeAllDialog({procedureData: {name, id: subProcedureId}})}
                                disabled={!pendingActionsCount || (!isRspp && !isHse)}
                            >
                                {t('acknowledge_all')}
                            </MethodologyButton>
                        </Grid>
                    
                    </Grid>
                </AccordionActions>
                <Divider style={{marginTop: 24}}/>
                <AccordionDetails>
                        {/*<Grid item style={{height: 'fit-content'}}>*/}
                        {/*    <DataGrid*/}
                        {/*        columns={tableColumns}*/}
                        {/*        rows={tableRows}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {!!alerts.filter((alert) => (!hideAcknowledged) || !(alert.acknowledged_at)).filter((alert) => (!hideNoAction) || !(alert.prescribed_action === 'nessuna')).length && (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <MethodologyTableCell width={'18%'}>
                                                {capitalizeFirstLetter(t('date_and_time'))}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'5%'}>
                                                {t('alert_level')}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'5%'}>
                                                {t('event_details')}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'10%'}>
                                                {capitalizeFirstLetter(t('prescribed_action'))}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'10%'}>
                                                {capitalizeFirstLetter(t('access_restriction'))}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'20%'}>
                                                {t('acknowledged_by')}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell width={'15%'}>
                                                {t('acknowledged_at')}
                                            </MethodologyTableCell>
                                            <MethodologyTableCell align={'right'}>
                                                {/*{t('acknowledge')}*/}
                                            </MethodologyTableCell>
                                        </TableHead>
                                        <TableBody>
                                            {alerts
                                            .filter((alert) => (!hideAcknowledged) || !(alert.acknowledged_at))
                                            .filter((alert) => (!hideNoAction) || !(alert.prescribed_action === 'nessuna'))
                                            .map((alert) => {
                                                const alertLevel = alertLevelScore(alert)
                                                return (
                                                    <TableRow key={alert.id}>
                                                        <MethodologyTableCell>
                                                            <Typography>
                                                                {!!alert?.event && eventSequentialId(alert?.event)}
                                                            </Typography>
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell align={'center'}>
                                                            <AlertBadge alertLevel={alertLevel} />
                                                            <span style={{ marginLeft: 5 }}>
                                                                {alertLevel - 1}
                                                            </span>
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell align={'center'}>
                                                            <MethodologyTooltip
                                                                title={t('event_details')}
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        push(
                                                                            `/procedures/${procedureId}/hexo/events/${alert?.event?.id}`
                                                                        )
                                                                    }
                                                                    size={'small'}
                                                                >
                                                                    <OpenInNew fontSize={'small'} />
                                                                </IconButton>
                                                            </MethodologyTooltip>
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell>
                                                            {t(alert.prescribed_action)}
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell>
                                                            {t(alert.access_restriction)}
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell>
                                                            <Typography>
                                                                {!!alert?.acknowledged_by &&
                                                                    `${
                                                                        alert.acknowledged_by
                                                                            .first_name || ''
                                                                    } ${
                                                                        alert.acknowledged_by
                                                                            .last_name || ''
                                                                    }`}
                                                            </Typography>
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell>
                                                            <Typography>
                                                                {!!alert?.acknowledged_at &&
                                                                    dayjs(
                                                                        alert.acknowledged_at
                                                                    ).format('YYYY-MM-DD HH:mm:ss')}
                                                            </Typography>
                                                        </MethodologyTableCell>
                                                        <MethodologyTableCell align={'right'}>
                                                            <MethodologyButton
                                                                disabled={!!alert?.acknowledged_at || (!isRspp && !isHse)}
                                                                onClick={() =>
                                                                    openAcknowledgeDialog({
                                                                        procedureData: {
                                                                            name,
                                                                            id: subProcedureId,
                                                                        },
                                                                        alertData: alert,
                                                                    })
                                                                }
                                                            >
                                                                {t('acknowledge')}
                                                            </MethodologyButton>
                                                        </MethodologyTableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        )}
                </AccordionDetails>
                <AcknowledgeAllDialog controls={acknowledgeAllDialogControls} />
                <AcknowledgeDialog  controls={acknowledgeDialogControls}/>
            </Accordion>
        </>
    )
}


