import React from 'react'
import { Paper, List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import useProcedureId from '../../../customHooks/useProcedureId'
import useMethodology from "../../../customHooks/useMethodology";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 240,
        overflow: 'hidden'
    },
    list: {
        background: theme.palette.primary.main,
    },
    levelBtn: {
        // textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
    },
    // material-ui override
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

export const routeDescriptionsByMethodology = Object.freeze({
    dynamics: [
        {
            title: 'dynamics_title',
            name: 'overview',
            submenus: [
                { title: 'dynamics_index', name: 'index' },
                { title: 'modal_analyses', name: 'modale' },
                { title: 'thresholds_analyses', name: 'soglie' },
            ],
        },
        {
            title: 'dynamics_configuration',
            name: 'configure',
            submenus: [
                { title: 'admin.plc', name: 'plcs' },
                { title: 'analysis_analysis', name: 'analyses' },
            ],
        },
    ],

    seismic_monitoring: [
        {
            title: 'system_status',
            name: 'overview'
        },
        {
            title: 'telemetry',
            name: 'telemetry'
        },
        {
            title: 'events',
            name: 'events'
        },
        {
            title: 'configuration_parameters',
            name: 'configuration'
        },
    ],
    
    hexo: [
        {
            title: 'buildings_state',
            name: 'buildings'
        },
        {
            title: 'events',
            name: 'events'
        },
        {
            title: 'staff',
            name: 'staff'
        },
        // {
        //     title: 'configuration',
        //     name: 'configuration'
        // },
        // {
        //     title:'telemetry',
        //     name:'telemetry'
        // },
    ],
    
    undefined: []
})

const useAccordionItems = () => {
    const { replace } = useHistory()
    const { pathname, search } = useLocation()
    const { procedureId } = useProcedureId();
    const { methodology } = useMethodology()
    const accordionItemDescriptions = routeDescriptionsByMethodology[methodology]
    
    // pathname structure: '/procedures/<procedureId>/<methodologyName>/<__selectedItemName__>[/maybe_a_submenuName]'
    const [selectedItemName, setSelectedItemName] = React.useState(pathname.replace(/^\/([^/]*\/){3}/, '').replace(/\/.*/, ''))
    
    const accordionItems = React.useMemo(() => {
        return accordionItemDescriptions.map((item) => (
            !item?.submenus?.length ? {
                ...item,
                path: `/procedures/${procedureId}/${methodology}/${item.name}`,
                isOpen: selectedItemName === item.name,
                onClick: () => replace(`/procedures/${procedureId}/${methodology}/${item.name}${search}`)
            } : {
                ...item,
                path: `/procedures/${procedureId}/${methodology}/${item.name}`,
                isOpen: selectedItemName === item.name,
                onClick: () => setSelectedItemName((selectedItemName) => selectedItemName === item.name ? undefined : item.name),
                submenus: item.submenus.map((submenuItem) => ({
                    ...submenuItem,
                    path: `/procedures/${procedureId}/${methodology}/${item.name}/${submenuItem.name}`,
                    onClick: () => replace(`/procedures/${procedureId}/${methodology}/${item.name}/${submenuItem.name}${search}`)
                })),
            }
        ))
    }, [procedureId, replace, selectedItemName, setSelectedItemName, accordionItemDescriptions, methodology, search])
    
    return {accordionItems}
    
}


export default function MethodologyToolbar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const currentUrl = useLocation().pathname
    
    const {accordionItems} = useAccordionItems()
    
    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>
                {accordionItems.map((item, i) => {
                    return (
                        !item.submenus?.length ? (
                            <React.Fragment key={item.path}>
                                {!!i && <Divider light variant="middle" />}
                                <ListItem
                                    button
                                    selected={_.includes(currentUrl, item.path)}
                                    onClick={item.onClick}
                                    classes={{
                                        root: classes.root,
                                        selected: classes.selected,
                                    }}
                                >
                                    <ListItemText
                                        style={{ textAlign: 'center' }}
                                        primary={_.capitalize(t(item.title))}
                                    />
                                </ListItem>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={item.path}>
                                {!!i && <Divider light variant="middle" />}
                                <ListItem
                                    button
                                    classes={{
                                        root: classes.root,
                                        selected: classes.selected,
                                    }}
                                    onClick={item.onClick}
                                    selected={_.includes(currentUrl, item.path) && (!item?.submenus?.length || !item.isOpen)}
                                >
                                    <ListItemText
                                        primary={t(item.title)}
                                        primaryTypographyProps={{
                                            className: classes.levelBtn,
                                        }}
                                    />
                                    {item.isOpen ? (
                                        <ExpandLess style={{ position: 'absolute' }} />
                                    ) : (
                                        <ExpandMore style={{ position: 'absolute' }} />
                                    )}
                                </ListItem>
                                <Collapse in={item.isOpen} timeout="auto">
                                    <List component="div" disablePadding>
                                        {item.submenus.map((submenu, i) => (
                                            <React.Fragment key={submenu.path}>
                                                {!!i && <Divider light variant="middle" />}
                                                <ListItem
                                                    button
                                                    selected={_.includes(currentUrl, submenu.path)}
                                                    onClick={submenu.onClick}
                                                    classes={{
                                                        root: classes.root,
                                                        selected: classes.selected,
                                                    }}
                                                >
                                                    <ListItemText
                                                        style={{ textAlign: 'center' }}
                                                        primary={_.capitalize(t(submenu.title))}
                                                    />
                                                </ListItem>
                                            </React.Fragment>
                                            
                                        
                                        ))}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                    )
                })}
            </List>
        </Paper>
    )
}
