import React from 'react'
import {
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    Select,
    MenuItem,
    Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Switch from '@material-ui/core/Switch'
import _ from 'lodash'
import SearchDialog from '../Dialogs/SearchDialog'
import { useParams, Link } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
    accessControlQueries,
    companiesQueries,
    proceduresQueries,
    queryClient,
    usersQueries,
    availableCompaniesQueries,
} from '../../networking'
import { useTranslation } from 'react-i18next'
import { useDebouncedValue } from '../../utils'
import permissions from '../../accessControl'
import CustomTable from '../CustomTable'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import AlertDialog from '../Dialogs/AlertDialog'
import { useSnackBarStore } from '../../stateManagement'
import { useQueryUserDetails } from "../../customHooks/queryHooks/userQueries";
import { useQueryProcedure } from "../../customHooks/queryHooks/procedureQueries";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    iconButton: {
        marginLeft: theme.spacing(1),
    },
    root: {
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
}))

export default function PermissionPicker({ entity_type = 'procedure', entity_id }) {
    const { t } = useTranslation()
    const { procedureId } = useParams()
    const classes = useStyles()

    const showSnackBar = useSnackBarStore((state) => state.show)
    const [searchedUserEmail, setSearchedUserEmail] = React.useState('')
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [isUserAddDialogOpen, setIsUserAddDialogOpen] = React.useState(false)
    const [owner, setOwner] = React.useState('')
    const [deleteDialog, setDeleteDialog] = React.useState({
        isOpen: false,
        id: null,
        action: null,
    })
    const [tablesData, setTablesData] = React.useState({
        companies: [],
        users: [],
    })
    const debouncedSearchedUser = useDebouncedValue(searchedUserEmail, 1000)

    const { data: companies = [], refetch: refetchCompanies } = useQuery(
        availableCompaniesQueries.getAvailableCompanies.name,
        availableCompaniesQueries.getAvailableCompanies.fn
    )
    const { data: userSearchData = [] } = useQueryUserDetails({email: debouncedSearchedUser})

    const { data: readPermissionData } = useQuery(
        [accessControlQueries.getAcl.name, 'read'],
        () => accessControlQueries.getAcl.fn([permissions.READ, entity_type, entity_id]),
        { enabled: !!entity_type && !!entity_id }
    )
    const { data: writePermissionData } = useQuery(
        [accessControlQueries.getAcl.name, 'write'],
        () => accessControlQueries.getAcl.fn([permissions.WRITE, entity_type, entity_id]),
        { enabled: !!entity_type && !!entity_id }
    )

    const { data: procedure } = useQueryProcedure({procedureId})

    useQuery(
        [companiesQueries.getCompany.name, procedure?.owner.id],
        () => companiesQueries.getCompany.fn(procedure.owner.id),
        {
            enabled: !!procedure,
            onSuccess: (data) => {
                setOwner(data.name)
            },
        }
    )

    const grantPermissionMutation = useMutation(
        ([type, id, permission]) => accessControlQueries.grantPermission.fn(id, type, permission),
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(accessControlQueries.getAcl.name, variables[2][0])
            },
        }
    )

    const revokePermissionMutation = useMutation(
        ([type, id, permission]) => accessControlQueries.revokePermission.fn(id, type, permission),
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(accessControlQueries.getAcl.name, variables[2][0])
            },
        }
    )

    React.useEffect(() => {
        function prepareData() {
            // permissions come in a flat structure from BE, now group them by read/write/company/user
            const companies = {}
            const users = {}
            for (let company of readPermissionData?.companies || []) {
                companies[company.id] = {
                    ...companies[company.id],
                    read: true,
                    name: company.name,
                    id: company.id,
                }
            }
            for (let user of readPermissionData?.users || []) {
                users[user.id] = {
                    ...users[user.id],
                    read: true,
                    name: user.first_name + ' ' + user.last_name,
                    id: user.id,
                }
            }

            for (let company of writePermissionData?.companies || []) {
                companies[company.id] = {
                    ...companies[company.id],
                    write: true,
                    name: company.name,
                    id: company.id,
                }
            }
            for (let user of writePermissionData?.users || []) {
                users[user.id] = {
                    ...users[user.id],
                    write: true,
                    name: user.first_name + ' ' + user.last_name,
                    id: user.id,
                }
            }

            setTablesData({
                companies: _.map(companies, (c) => c),
                users: _.map(users, (u) => u),
            })
        }
        prepareData()
    }, [writePermissionData, readPermissionData])

    function handleUserSearch(query) {
        if (query !== searchedUserEmail) {
            setSearchedUserEmail(query)
        }
    }

    function openCompanyDialog() {
        setIsUserAddDialogOpen(false)
        setIsDialogOpen(true)
    }

    function openUserDialog() {
        setIsUserAddDialogOpen(true)
        setIsDialogOpen(false)
    }

    function handleUserSelect(selectedUserId) {
        if (userSearchData?.length) {
            const user = userSearchData.find((x) => x.id === selectedUserId)
            user.name = user.first_name + ' ' + user.last_name

            if (!tablesData.users.find((x) => x.id === selectedUserId)) {
                grantPermissionMutation.mutate(['user', user.id, ['read', entity_type, entity_id]])
                setTablesData({
                    ...tablesData,
                    users: [...tablesData.users, user],
                })
            }
        }
        setIsUserAddDialogOpen(false)
    }
    function handleCompanySelect(selectedCompanyId) {
        if (companies?.length) {
            const company = companies.find((x) => x.id === selectedCompanyId)
            if (!tablesData.companies.find((x) => x.id === selectedCompanyId)) {
                grantPermissionMutation.mutate([
                    'company',
                    company.id,
                    ['read', entity_type, entity_id],
                ])

                setTablesData({
                    ...tablesData,
                    companies: [...tablesData.companies, company],
                })
            }
        }
        setIsDialogOpen(false)
    }

    const [isEditable, setIsEditable] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState()
    const [availableCompanies, setAvailableCompanies] = React.useState([])
    const [isAdmin, setIsAdmin] = React.useState(true)
    const [isOpen, setIsOpen] = React.useState(false)

    const { refetch: refetchCompaniesList } = useQuery(
        [availableCompaniesQueries.getAvailableCompanies.name],
        () => availableCompaniesQueries.getAvailableCompanies.fn(),
        {
            onSuccess: (companies) => {
                if (companies.length === 0) setIsAdmin(false)
                else handleData(owner, companies)
            },
            enabled: !!owner,
        }
    )

    const updateCompany = useMutation(([queryProcedureId, queryCompanyId]) =>
        availableCompaniesQueries.updateProcedureCompany.fn(queryProcedureId, queryCompanyId)
    )

    const handleData = (companyName, companies) => {
        if (!companyName) return
        const filteredList = companies.filter((item) => item.name !== companyName)
        setAvailableCompanies(filteredList)

        // Build selected company state after first fetch
        if (selectedCompany === undefined) {
            const currentId = companies.find((item) => item.name === companyName)?.id
            setSelectedCompany({ id: currentId, name: companyName })
        }
    }

    const handleChange = (event) => {
        setIsOpen(false)

        let name = availableCompanies.find((item) => item.id === event.target.value).name
        if (name === undefined) name = owner

        setSelectedCompany({ id: event.target.value, name: name })

        refetchCompaniesList().then((data) => {
            handleData(name, data.data)
        })
    }

    const revokePermission = (id) => {
        revokePermissionMutation
            .mutateAsync(['company', id, ['read', entity_type, entity_id]])
            .then(() =>
                revokePermissionMutation
                    .mutateAsync(['company', id, ['write', entity_type, entity_id]])
                    .then(() => refetchCompanies())
            )
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    const toggleEditable = () => {
        if (owner !== selectedCompany.name) {
            setOwner(selectedCompany.name)
        }
        if (!isEditable) setIsOpen(true)

        setIsEditable(!isEditable)
    }

    const handleCompanyEditSubmit = () => {
        updateCompany.mutateAsync([entity_id, selectedCompany.id]).then(() => {
            setOwner(selectedCompany.name)
            revokePermission(selectedCompany.id)
            toggleEditable()
        })
    }

    const handleWritePermissionToggle = (checked, id, action = 'user' || 'company') => {
        if (checked) {
            revokePermissionMutation.mutate([action, id, ['write', entity_type, entity_id]])
            showSnackBar({ message: t('removed_write_permission'), severity: 'success' })
        } else if (!checked) {
            grantPermissionMutation.mutate([action, id, ['write', entity_type, entity_id]])
            showSnackBar({ message: t('added_write_permission'), severity: 'success' })
        }
    }

    const removeUser = (id, action = 'user' || 'company') => {
        revokePermissionMutation
            .mutateAsync([action, id, ['write', entity_type, entity_id]])
            .then(() =>
                revokePermissionMutation
                    .mutateAsync([action, id, ['read', entity_type, entity_id]])
                    .then(() => setDeleteDialog({ isOpen: false, id: null, action: null }))
            )
    }

    const user_company_compareFn = (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom paragraph>
                {t('permissions_page_title')}
            </Typography>
            <Paper elevation={0} className={classes.paper}>
                <Grid container direction="column">
                    <Grid item container alignItems="center" style={{ marginBottom: 16 }}>
                        <Typography variant="h6">{_.capitalize(t('procedure'))}:&nbsp;</Typography>
                        <Link to={`/procedures/${procedureId}`}>
                            <Typography
                                variant="h6"
                                classes={{
                                    root: classes.root,
                                }}
                            >
                                {procedure?.name}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item container alignItems="center">
                        <Typography variant="h6">
                            {_.capitalize(t('company'))}:&nbsp;
                            {isEditable ? (
                                <Select
                                    open={isOpen}
                                    onClick={toggleMenu}
                                    value={selectedCompany.id}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={selectedCompany.id}>
                                        {selectedCompany.name}
                                    </MenuItem>

                                    {availableCompanies.map((item, idx) => (
                                        <MenuItem key={idx} id={idx} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                owner
                            )}
                        </Typography>

                        {isAdmin &&
                            (isEditable ? (
                                <IconButton
                                    className={classes.iconButton}
                                    color="secondary"
                                    size="small"
                                    onClick={handleCompanyEditSubmit}
                                >
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    className={classes.iconButton}
                                    color="secondary"
                                    size="small"
                                    onClick={toggleEditable}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            ))}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction={'row'}
                    alignItems={'center'}
                    style={{ marginTop: 20, marginBottom: 10 }}
                >
                    <Typography variant={'h6'} component={'span'}>
                        {t('studi')}
                    </Typography>
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        color="secondary"
                        onClick={openCompanyDialog}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
                <CustomTable
                    headers={['Nome', 'scrittura', '']}
                    data={tablesData.companies.sort(user_company_compareFn) || []}
                    renderRow={(item) => (
                        <TableRow key={item.id}>
                            <TableCell
                                component="th"
                                scope="row"
                                style={{
                                    minWidth: 300,
                                }}
                            >
                                {item.name}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: 150,
                                }}
                                component="th"
                                scope="row"
                            >
                                <Switch
                                    checked={Boolean(item.write)}
                                    onChange={() => {
                                        handleWritePermissionToggle(item.write, item.id, 'company')
                                    }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                        setDeleteDialog({
                                            isOpen: true,
                                            id: item.id,
                                            action: 'company',
                                        })
                                    }
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                />
                <Grid
                    item
                    container
                    direction={'row'}
                    alignItems={'center'}
                    style={{ marginTop: 20, marginBottom: 10 }}
                >
                    <Typography variant={'h6'} component={'span'}>
                        {t('utenti')}
                    </Typography>
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        color="secondary"
                        onClick={openUserDialog}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
                <CustomTable
                    headers={['Nome', 'Scrittura', '']}
                    data={tablesData.users.sort(user_company_compareFn) || []}
                    renderRow={(item) => (
                        <TableRow key={item.id}>
                            <TableCell
                                component="th"
                                scope="row"
                                style={{
                                    minWidth: 300,
                                }}
                            >
                                {item.name}
                            </TableCell>
                            <TableCell style={{ width: 150 }} component="th" scope="row">
                                <Switch
                                    checked={Boolean(item.write)}
                                    onChange={() => {
                                        handleWritePermissionToggle(item.write, item.id, 'user')
                                    }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                        setDeleteDialog({
                                            isOpen: true,
                                            id: item.id,
                                            action: 'user',
                                        })
                                    }
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                />
                <Grid item xs={12}>
                    <SearchDialog
                        data={userSearchData.map((x) => ({
                            item: { id: x.id },
                            ...x,
                        }))}
                        title={'permissions_search_user_dialog_title'}
                        subtitle={'permissions_search_user_dialog_subtitle'}
                        open={isUserAddDialogOpen}
                        setIsOpen={setIsUserAddDialogOpen}
                        useLocalSearch={false}
                        onSearchChange={handleUserSearch}
                        handleResultClick={handleUserSelect}
                    />
                    <SearchDialog
                        title={'permissions_search_company_dialog_title'}
                        data={companies}
                        open={isDialogOpen}
                        setIsOpen={setIsDialogOpen}
                        handleResultClick={handleCompanySelect}
                    />
                    <AlertDialog
                        open={deleteDialog.isOpen}
                        setIsOpen={() => {
                            setDeleteDialog({ isOpen: false, id: null, action: null })
                        }}
                        title={`Rimuovere ${
                            deleteDialog.action === 'company' ? 'organizzazione' : 'utente'
                        }?`}
                        primaryButton={t('remove')}
                        onSubmitCb={() => {
                            removeUser(deleteDialog.id, deleteDialog.action)
                        }}
                    />
                </Grid>
            </Paper>
        </>
    )
}
