import React from 'react'

export default function useDialogControls(initialState ) {
    const [isOpen, setOpen] = React.useState(false)
    const [state, setState] = React.useState(initialState || {})
    
    const open = React.useCallback(
        (state = undefined) => {
            if (state) {
                setState(state)
            }
            setOpen(true)
        },
        [setOpen, setState]
    )
    const close = React.useCallback(() => setOpen(false), [setOpen])
    
    
    return { isOpen, open, close, state, setState }
}
