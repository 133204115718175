import React from "react";
import MethodologyPage from "./MethodologyPage";
import SismaToolRouter from "./methodologies/sisma_tool/SismaToolRouter";
import { Route, Switch } from "react-router-dom";
import { Grid } from "@material-ui/core";

const methodologyRouters = Object.freeze({
    hexo: SismaToolRouter
})

function ProcedureMethodologyRouter() {
    return (
        <MethodologyPage maxWidth={''}>
            <Grid container spacing={3} direction="column">
                <Switch>
                    {Object.entries(methodologyRouters).map(([methodology, component]) => (
                        <Route
                            key={methodology}
                            path={`/procedures/:procedureId/${methodology}`}
                            component={component}
                        />
                    ))}
                </Switch>
            </Grid>
        </MethodologyPage>
    )
}

export default ProcedureMethodologyRouter;