import useProcedureId from '../../useProcedureId'
import { useQuery } from 'react-query'
import axios from 'axios'
import detailedAnalysisResults from '../../../components/3sAnalysisResults/analysis_result_example.json'
import { useAnalysisId, usePlcId, useResultId } from '../../createUseParam'

export function useQueryProcedurePlcConfigurations(procedure_plc_configuration_id) {
    const { procedureId } = useProcedureId()
    const { plcId } = usePlcId()

    return useQuery(
        ['getProcedurePlcConfigurations', procedureId, plcId, procedure_plc_configuration_id],
        async () => {
            let url = `/dynamics/configurations?plc_id=${plcId}&procedure_id=${procedureId}`
            if (procedure_plc_configuration_id)
                url += '&procedure_plc_configuration_id=' + procedure_plc_configuration_id
            const response = await axios.get(url)
            return response.data
        }
    )
}

export function useQueryPlcs() {
    const { plcId } = usePlcId()
    return useQuery(
        ['getPlcs'],
        async () => (await axios.get((plcId && `/admin/plc/${plcId}`) || '/admin/plc')).data
    )
}

export function useQueryFieldPcs() {
    return useQuery(['getFieldPcs'], async () => (await axios.get('/admin/field_pc')).data)
}

export function useQueryProcedureAnalyses() {
    const { procedureId } = useProcedureId()
    return useQuery(
        ['getProcedureAnalyses', procedureId],
        async () => (await axios.get('/dynamics/analyses?procedure_id=' + procedureId)).data,

        { enabled: !!procedureId }
    )
}

export function useQueryProcedureAnalysisResults() {
    const { analysisId } = useAnalysisId()
    return useQuery(
        ['getProcedureAnalysisResults', analysisId],
        async () => (await axios.get(`/dynamics/analyses/${analysisId}/results`)).data
    )
}

export function useQueryProcedureAnalysisResultDetails() {
    const { resultId } = useResultId()
    return useQuery(
        ['getProcedureAnalysisResultDetails', resultId],
        // async () => (await axios.get(`/analyses/${analysisId}/results`)).data,

        // mock api call
        () =>
            new Promise((resolve) => {
                return resolve(detailedAnalysisResults.find((result) => result.id === resultId))
            }),
        { enabled: !!resultId }
    )
}
