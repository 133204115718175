import { useQuerySismaToolUnprotectedProcedureData } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { alertChar, alertLevelScore, MethodologySectionPaper, MethodologySectionTitle } from "../../components";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";

const useStyles = makeStyles(theme => ({
    outerContainer: {
        // margin: theme.spacing(4),
        width: '100vw',
        height: '100vH',
        // border: '1px solid green',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.page,
        // ':first-child': {marginTop: 100}
        
    },
    
    innerContainer: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        
        // border: '1px solid red'
    },
    
    bigText: {
        // fontSize: 60
    }
    
    
    
}))

export default function SismaToolProcedureUnprotectedView({ match }) {
    const classes = useStyles()
    const { data: procedureData } = useQuerySismaToolUnprotectedProcedureData()
    const aggregateAlertLevel = alertLevelScore({ alert_level: procedureData?.result_extra_info?.aggregate_status?.alert_level })
    const notOkSince = procedureData?.result_extra_info?.aggregate_status?.not_ok_since?.$date
        ? new Date(procedureData?.result_extra_info?.aggregate_status?.not_ok_since?.$date)
        : null
    const lastRefresh = procedureData?.last_refresh ? new Date(procedureData?.last_refresh * 1000) : null
    
    return (
        <Grid
            container
            className={classes.outerContainer}
            alignItems={'canter'}
            justifyContent={'center'}
        >
            <Grid
                className={classes.innerContainer}
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                    <MethodologySectionPaper>
                        <MethodologySectionTitle>
                            {`Stato del sito ${procedureData?.procedure_name}`}
                        </MethodologySectionTitle>
                        <Typography align={'center'} className={classes.bigText}>Stato complessivo: {alertChar(aggregateAlertLevel)}</Typography>
                        {!!notOkSince && <Typography align={'center'} className={classes.bigText}>In allerta da: {notOkSince.toLocaleString()}</Typography>}
                        {!!lastRefresh && <Typography align={'center'} className={classes.bigText}>Ultimo aggiornamento: {lastRefresh.toLocaleString()}</Typography>}
                    </MethodologySectionPaper>
            </Grid>


            {/*<pre style={{ border: '4px solid blue', width: 'fit-content' }}>*/}
            {/*    {JSON.stringify(procedureData, null, 4)}*/}
            {/*</pre>*/}
        </Grid>
    )
}