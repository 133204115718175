import { makeStyles } from "@material-ui/core/styles";
import { AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, Fab, Grid, Paper, Table, TableCell, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import SaveIcon from "@material-ui/icons/Save";
import dayjs from "dayjs";

export const useMethodologyStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    avatar: {
        width: 24,
        height: 24,
        fontSize: '0.85rem',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginLeft: 10,
    },
    summary: {
        justifyContent: 'space-around',
        
    },
    customChip: {
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
        marginRight: '10px',
        borderRadius: '10px',
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'center',
    },
    customChipMobile: {
        fontSize: '11px',
        height: '16px',
        borderRadius: '8px',
        padding: '0 4px',
    },
    tooltip: {
        maxWidth: 'none', // Removes the default width limit
        width: 'fit-content', // Ensures the tooltip width fits the content
        whiteSpace: 'pre-wrap', // Maintains formatting and wraps long lines
        fontSize: '0.8rem'
    },
    button: {
        alignSelf: 'flex-end',
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
    },
    sectionTitle: {
        position: 'absolute',
        // bottom: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.background.default,
        paddingLeft: '15px',
        paddingRight: '15px',
        color: theme.palette.secondary.main,
    },
    sectionDividerContainer: {
        position: 'relative',
        marginTop: '30px',
        marginBottom: '15px',
    },
    cardBody: {
        minWidth: '252px',
    },
    teamContainer: {
        margin: 0,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        
    },
}))

export function MethodologySectionPaper({children}) {
    const { paper, overlay } = useMethodologyStyles()
    return (
        <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={overlay}
        >
            <Paper className={paper} >
                    {children}
            </Paper>
        </Grid>
    )
}

export function MethodologySectionTitle({children}) {
    
    return (
        <Typography variant="h6" component="h2" align="center" gutterBottom>
            {children}
        </Typography>
    )
}

export function MethodologyAccordionSummary({ children, rightAdornment }) {
    const { summary } = useMethodologyStyles()
    return (
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={summary} >
            <Grid container xs={12} display={'flex'} direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    {children}
                </Grid>
                <Grid item style={{marginRight: -8}}>
                    {rightAdornment}
                </Grid>
            </Grid>
        </AccordionSummary>
    )
}

export function MethodologyAccordionDetails({children}) {
    return <AccordionDetails>
        {children}
    </AccordionDetails>
}

export function MethodologyTooltip({ title, children }) {
    const { tooltip } = useMethodologyStyles()
    return (
        <Tooltip title={title} className={tooltip} placement="top">
            { children }
        </Tooltip>
    )
}

export function MethodologyTitledDivider({ children }) {
    const classes = useMethodologyStyles()
    return (
        <Grid item className={classes.sectionDividerContainer}>
            <Divider className={classes.divider} />
            <Typography
                variant="h6"
                component="h2"
                align="center"
                className={classes.sectionTitle}
            >
                {children}
            </Typography>
        </Grid>
    )
}

export function MethodologyButton({ children, onClick, variant, color, size, disabled, type }) {
    return <Button
        variant={variant || 'contained'}
        color={color || 'secondary'}
        type={type}
        size={size || 'small'}
        onClick={onClick}
        disabled={!!disabled}
        style={{boxShadow: 'none'}}
    >
        {children}
    </Button>
}

export function MethodologyFab({ children, onClick, variant, color, size, disabled, type }) {
    const { fab } = useMethodologyStyles()
    return <Fab
        variant={variant || 'extended'}
        color={color || 'secondary'}
        type={type}
        size={size || 'small'}
        onClick={onClick}
        disabled={!!disabled}
        className={fab}
    >
        {children}
    </Fab>
}

export function alertLevelScore(alert) {
    return {
        nessuna_allerta: 1,
        allerta_0: 1,
        allerta_1: 2,
        allerta_2: 3,
        "N/A": 0
    }[alert?.alert_level] ?? 0
}

export function eventLevelScore(event) {
    if (!event?.pga_max_microg) return 0
    let alertLevel = 0
    if (event?.sisma_tool_configuration?.thresholds?.allerta_0_microg && event?.sisma_tool_configuration?.thresholds?.allerta_0_microg <= event.pga_max_microg) alertLevel = 1
    if (event?.sisma_tool_configuration?.thresholds?.allerta_1_microg && event?.sisma_tool_configuration?.thresholds?.allerta_1_microg <= event.pga_max_microg) alertLevel = 2
    if (event?.sisma_tool_configuration?.thresholds?.allerta_2_microg && event?.sisma_tool_configuration?.thresholds?.allerta_2_microg <= event.pga_max_microg) alertLevel = 3
    return alertLevel
}

export function AlertBadge({alertLevel}) {
    const { t } = useTranslation()
    const alertLevelName = ['N/A', 'allerta_0', 'allerta_1', 'allerta_2'][alertLevel]
    const alertLevelIcon = ['⚪', '🟢', '🟠', '🔴'][alertLevel]
    
    return <MethodologyTooltip title={t(alertLevelName)}><span style={{marginRight: 4, marginLeft: 4}}>{alertLevelIcon}</span></MethodologyTooltip>
}

export function alertChar(alertLevel) {
    return ['⚪', '🟢', '🟠', '🔴'][alertLevel] ?? 'N/A'
}

export function MethodologyTable({children}) {
    return <Table>
        {children}
    </Table>
}

export function MethodologyTableCell({children, width, align}) {
    return <TableCell size={'small'} width={width} align={align}>{children}</TableCell>
}

export function eventSequentialId({event_began_at_timestamp, sequential_id}) {
    return dayjs(event_began_at_timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') + (sequential_id ? ` (${sequential_id})` : '')
}