import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { MethodologyButton } from "../../components";
import { useTranslation } from "react-i18next";
import { useMutateSismaToolStaff, useQuerySismaToolStaff } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import { useQueryUserDetails } from "../../../../customHooks/queryHooks/userQueries";
import { useDebouncedValue } from "../../../../utils";
import { useSnackBarStore } from "../../../../stateManagement";

const config = {
    rspp: {
        title: 'add_rspp_member',
        content: 'add_rspp_member_dialog_content',
    },
    hse: {
        title: 'add_hse_member',
        content: 'add_hse_member_dialog_content'
    },
    external: {
        title: 'add_hse_external_member',
        content: 'add_hse_external_member_dialog_content'
    }
}

export default function AddStaffMemberDialog({controls}) {
    const { t } = useTranslation()
    const showSnackbar = useSnackBarStore(state => state.show)
    const { isOpen, close, state } = controls
    const role = state?.role || 'rspp'
    const action = `toggle_${role}`
    
    const { title } = role && config[role] || {}
    
    const { data: staff } = useQuerySismaToolStaff()
    const { mutateAsync: addStaffMember } = useMutateSismaToolStaff({action})
    
    
    const [_email, setEmail] = React.useState('')
    const email = useDebouncedValue(_email, 500)
    
    const {data: userDetails} = useQueryUserDetails({email})
    console.log({role})
    const validSelection = (userDetails || []).at(0)?.email && !staff[role].find(member => member.email === userDetails.at(0)?.email)
    const alreadyMember = staff && staff[role].find(member => member?.email === (userDetails || []).at(0)?.email)
    
    React.useEffect(() => {
        if (alreadyMember) { showSnackbar({message: 'Utente già inserito', severity: 'error'})}
    }, [alreadyMember, showSnackbar])
    
    
    return (
        <Dialog open={isOpen} onClose={close} fullWidth={true} maxWidth="md">
            <DialogTitle>{t(title)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item>
                        <Typography>
                            Seleziona il profilo da aggiungere per il ruolo {role}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant={'outlined'}
                            value={_email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!validSelection}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <MethodologyButton variant={'contained'} color={'primary'} onClick={close}>
                    Annulla
                </MethodologyButton>
                <MethodologyButton
                    disabled={!validSelection}
                    onClick={async () => {
                        await addStaffMember({ userId: userDetails.at(0)?.id })
                        close()
                    }}
                >
                    Conferma
                </MethodologyButton>
            </DialogActions>
        </Dialog>
    )
}


