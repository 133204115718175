import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { MethodologyButton } from "../../components";
import { useTranslation } from "react-i18next";
import useCreateSismaToolEventForm from "../hooks/useCreateSismaToolEventForm";
import DynamicFormFields from "../../../../components/FormItems/DynamicFormFields";
import AppForm from "../../../../components/AppForm";
import { useCreateSismaToolEvent } from "../../../../customHooks/queryHooks/methodologyQueries/sismaToolQueries";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Box from "@material-ui/core/Box";



export default function CreateEventDialog({controls}) {
    const { t } = useTranslation()
    const { isOpen, close } = controls
    
    const {methods, fields, prepare, formId} = useCreateSismaToolEventForm()
    
    const {mutateAsync, isLoading} = useCreateSismaToolEvent()
    
    return (
        <Dialog open={isOpen} onClose={close} fullWidth={true} maxWidth="md">
            <AppForm
                methods={methods}
                onSubmit={(data) => mutateAsync(prepare(data)).then(() => close())}
            >
                <DialogTitle>{t('create_event')}</DialogTitle>
                <DialogContent>
                    <DynamicFormFields fieldsKey={''} formFields={fields} formId={formId} />
                    <pre />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <MethodologyButton variant={'contained'} color={'primary'} onClick={close}>
                        Annulla
                    </MethodologyButton>
                    <MethodologyButton type={'submit'} disabled={isLoading}>
                        {isLoading ? <LoadingSpinner size={10} /> : t('conferma')}
                    </MethodologyButton>
                </DialogActions>
            </AppForm>
        </Dialog>
    )
}


