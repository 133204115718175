import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import mit_guidelines_logo from '../../../assets/lgp/lgp_logo.png'
import resisto_logo from '../../../assets/resisto/resisto_logo.png'
import statico_logo from '../../../assets/statico_logo.png'
import sismaBonus_logo from '../../../assets/sismaBonus_logo.png'
// import dynamics_logo from '../../../assets/dynamics_logo.png'
import dynamics_dark from '../../../assets/methodology-logos/dynamics_dark.svg'
import dynamics_light from '../../../assets/methodology-logos/dynamics_light.svg'
import hexo_dark from '../../../assets/methodology-logos/hexo_dark.svg'
import hexo_light from '../../../assets/methodology-logos/hexo_light.svg'
import { colorsMapping } from '../../../customTheme'
import { useQueryProcedure } from '../../../customHooks/queryHooks/procedureQueries'
import { useThemeStore, useUserProfileStore } from "../../../stateManagement";
import { alertChar } from "../../../screens/methodologies/components";


const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: 100,
        width: '100%',
        background: theme.palette.background.contrast,
    },
    img: {
        width: 180,
        height: 80,
        // background: 'white',
        borderRadius: 8,
        backgroundColor: theme.palette.primary.contrastText,
    },
    text: {
        color: theme.palette.secondary.contrastText,
        marginRight: theme.spacing(),
    },
    classValue: {
        background: theme.palette.background.paperLight,
        color: theme.palette.secondary.main,
    },
    sticker: {
        minHeight: 30,
        borderRadius: theme.shape.borderRadius,
        padding: '5px 10px',
        color: theme.palette.common.white,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
    },
    zeroWidth: {
        width: 0,
        
    }
}))

const classeToNumber = {
    null: 0,
    BASSA: 1,
    MEDIOBASSA: 2,
    MEDIA: 3,
    MEDIOALTA: 4,
    ALTA: 5,
    
    nessuna_allerta: 2,
    allerta_0: 2,
    allerta_1: 4,
    allerta_2: 5
}

const classeToText = {
    null: 'N/A',
    BASSA: 'BASSA',
    MEDIOBASSA: 'MEDIOBASSA',
    MEDIA: 'MEDIA',
    MEDIOALTA: 'MEDIOALTA',
    ALTA: 'ALTA',

    nessuna_allerta: 'N/A',
    allerta_0: `Allerta 0`,
    allerta_1: `Allerta 1`,
    allerta_2: `Allerta 2`,
//     allerta_0: `${alertChar(0)}`,
//     allerta_1: `${alertChar(1)}`,
//     allerta_2: `${alertChar(2)}`,
}

const methodologyIcons = {
    mit_guidelines: {
        dark: mit_guidelines_logo,
        light: mit_guidelines_logo
    },
    resisto: {
        dark: resisto_logo,
        light: resisto_logo
    },
    dynamics: {
        dark: dynamics_dark,
        light: dynamics_light
    },
    hexo: {
        dark: hexo_dark,
        light: hexo_light
    },
    seismic_monitoring: {
        dark: dynamics_dark,
        light: dynamics_light
    }
}



export default function MethodologyCard({ methodology }) {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()
    const { data: procedure } = useQueryProcedure()
    const procedureId = procedure?.id
    const methodologyData = procedure?.methodologies[methodology]
    const themeMode = useThemeStore(state => state.themeMode)
    const isSuperAdmin = useUserProfileStore(state => state.isSuperAdmin)
    const methodologiesMapping = useMemo(() => {
        console.log({methodology, themeMode})
        const mapping = {
            mit_guidelines: {
                // logo: mit_guidelines_logo,
                url: `/procedures/${procedureId}/lgp`,
                backgroundSize: 'auto 90%',
            },
            resisto: {
                // logo: resisto_logo,
                url: `/procedures/${procedureId}/resisto/panoramica`,
                backgroundSize: 'auto 90%',
            },
            statico: {
                logo: statico_logo,
                url: `/procedures/${procedureId}/statico`,
                backgroundSize: 'auto 90%',
                hidden: true
            },
            sisma_bonus: {
                logo: sismaBonus_logo,
                url: `/procedures/${procedureId}/sisma_bonus`,
                backgroundSize: '90%',
                hidden: true
            },
            dynamics: {
                url: `/procedures/${procedureId}/dynamics/overview/index`,
                backgroundSize: '90%',
                hidden: isSuperAdmin === false
            },
            statics: {
                url: `/procedures/${procedureId}/statics/overview/index`,
                backgroundSize: '90%',
                hidden: true
            },
            seismic_monitoring: {
                // logo: dynamics_logo,
                url: `/procedures/${procedureId}/seismic_monitoring/overview`,
                backgroundSize: '90%',
            },
            hexo: {
                url: `/procedures/${procedureId}/hexo`,
                backgroundSize: '75%'
            }
        }
        console.log({methodology})
        return {
            logo: methodologyIcons[methodology]['light'],
            url: `/procedures/${procedureId}/${methodology}`,
            backgroundSize: '90%',
            ...mapping[methodology]
        }
    }, [procedureId, methodology, themeMode, isSuperAdmin])
    

    return (
        methodologiesMapping && (
            <CardActionArea
                onClick={() => {
                    if (methodologiesMapping.url) {
                        history.push(methodologiesMapping.url)
                    }
                }}
            >
                <Card
                    className={classes.card}

                >
                    <CardContent style={{ paddingBottom: 16 }}>
                        <Grid container wrap="nowrap" spacing={2} justifyContent="space-between" alignItems={'center'}>
                            <Grid item  xs={4}>
                                <CardMedia
                                    className={classes.img}
                                    style={{
                                        backgroundSize:
                                            methodologiesMapping.backgroundSize || 'contain',
                                    }}
                                    image={methodologiesMapping.logo}
                                />
                            </Grid>
                            <Grid item  xs={4} container justifyContent={'center'}>
                                <Typography variant="h6" color={'primary'}>{t(methodology)}</Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent={'flex-end'} alignItems={'center'}>
                                <Typography className={classes.text}>
                                    {t('classe_risultante')}
                                </Typography>
                                {methodologyData?.is_unlocked ? (
                                    <div
                                        className={classes.sticker}
                                        style={{
                                            background:
                                                colorsMapping[
                                                    classeToNumber[methodologyData?.latest_result]
                                                ],
                                        }}
                                    >
                                        <Typography>
                                            {t(classeToText[methodologyData?.latest_result] || 'N/D')}
                                        </Typography>
                                    </div>
                                ) : (
                                    <Avatar variant="rounded" className={classes.classValue}>
                                        <LockIcon fontSize="large" color="disabled" />
                                    </Avatar>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </CardActionArea>
        )
    )
}
