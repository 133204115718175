import axios from 'axios'
import { deepFreeze } from './utils'

export const backend_url =
    (window.backend_uri !== '{{backend_uri}}' ? window.backend_uri : 'http://localhost:80') +
    '/api/v1'
export const report_generator_url =
    window.report_generator_uri !== '{{report_generator_uri}}'
        ? window.report_generator_uri
        : 'http://localhost:3001'
export const environment = window.environment !== '{{environment}}' ? window.environment : 'dev'
export const google_maps_api_key =
    window.google_maps_api_key !== '{{google_maps_api_key}}'
        ? window.google_maps_api_key
        : 'AIzaSyDb8mSgB8_-Zaxbvj4b-lmsPnwWIFnF6WQ'

axios.defaults.baseURL = backend_url
axios.defaults.withCredentials = true

export const uninterceptedAxiosInstance = axios.create()

export const italyCenter = { lat: 41.70980865018976, lng: 11.452007188143401 }
export const mapLibraries = ['drawing', 'places']

export const structureTypes = Object.freeze(['bridge', 'building', 'windmill',  'facility'])
export const methodologies = Object.freeze([
    'lgp',
    'resisto',
    'dynamics',
    'statics',
    'seismic_monitoring',
    'hexo'
])
methodologies.forEach((methodology) => {
    if (!/^\w+$/.test(methodology)) throw new Error('invalid characters in a methodology name')
})

// TODO add new methodologies to bridge and building
export const methodologiesByStructureType = deepFreeze({
    bridge: ['lgp'],
    building: ['resisto', 'dynamics', 'seismic_monitoring'],
    windmill: ['statics', 'dynamics', 'seismic_monitoring'],
    facility: ['hexo']
})

export const AssetTypeEnum = Object.fromEntries(structureTypes.map(structureType => [structureType.toUpperCase(), structureType]))

export const structureTypesByMethodology = deepFreeze(
    Object.fromEntries(
        methodologies
            .map((methodology) => [
                methodology,
                structureTypes.filter((type) =>
                    methodologiesByStructureType[type].includes(methodology)
                ),
            ])
            .concat([['', []]])
    )
)

export const analysisTypes = Object.freeze(['modale', 'soglie'])

export const analysisExecutionTypes = Object.freeze(['time_interval', 'fixed_time'])

export const enelId = '60b8ff6acc5d9bed73a2bc3c'
