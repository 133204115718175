import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AppForm from '../../components/AppForm'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import { useSeismicMonitoringConfigurationFields } from '../../customHooks/formFields/useSeismicMonitoringConfigurationFields'
import { useCreateSeismicMonitoringConfiguration } from '../../customHooks/queryHooks/methodologyQueries/seismicMonitoringQueries'
import { useSnackBarStore } from '../../stateManagement'

export const SeismicMonitoringConfigurationModal = ({ open, onClose }) => {
    const { t } = useTranslation()
    const { mutateAsync } = useCreateSeismicMonitoringConfiguration()
    const methods = useForm({
        mode: 'onBlur',
    })
    const { formFields, prepare } = useSeismicMonitoringConfigurationFields(methods)
    const showSnackbar = useSnackBarStore((state) => state.show)

    const { isDirty, isValid } = methods.formState

    const onSubmit = async (data) => {
        try {
            await mutateAsync(prepare(data))
            showSnackbar({ message: 'configuration created', severity: 'success' })
            onClose()
        } catch (e) {
            showSnackbar({ message: e.message, severity: 'error' })
        }
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <AppForm onSubmit={onSubmit} methods={methods}>
                <DialogTitle>{t('create_seismic_monitoring_configuration')}</DialogTitle>
                <DialogContent>
                    <DynamicFormFields fieldsKey={''} formFields={formFields} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('close')}</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={!isDirty || !isValid}
                    >
                        {t('create')}
                    </Button>
                </DialogActions>
            </AppForm>
        </Dialog>
    )
}
